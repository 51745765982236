var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var q5,Esa,Fsa,Gsa,s5,v5,w5,x5,Hsa,Isa,A5,y5,z5,D5,E5,Jsa,Ksa,F5,Lsa,Msa,Nsa,Osa,Psa,Qsa,Rsa,H5,Ssa,Tsa,Usa,I5,Vsa,Wsa,J5,Xsa,Ysa,Zsa,$sa;q5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=q5($CLJS.J.g(a,b),c),$CLJS.od(c)?$CLJS.fk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.fk.g(a,b)}return a};
Esa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.Hl(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Cb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Ne.j(l,$CLJS.Cb(v),m)):f}(a,b,c,d)};Fsa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Esa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.r5=function(a){return $CLJS.AD.h($CLJS.ID(a))};
Gsa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.bP,$CLJS.z(b))?$CLJS.BV(d,function(){var f=$CLJS.RV.h(d);return $CLJS.n(f)?f:$CLJS.AV(c)}()):d;return Fsa(a,b,function(f){return $CLJS.yf(function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.E.g($CLJS.r5(C),$CLJS.r5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):
$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.E.g($CLJS.r5(x),$CLJS.r5(c))?e:x,m($CLJS.Hc(t)))}return null}},null,null)}(f)}())})};
s5=function(a,b,c,d){var e=$CLJS.Bz(a,b);if($CLJS.n(e)){var f=$CLJS.r5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.ed(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.Wf.j($CLJS.xf,$CLJS.nk.h($CLJS.mk.g($CLJS.Tg([f]),$CLJS.r5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.VD],null))&&$CLJS.Le(function(l){return $CLJS.I1(l,$CLJS.qA)},e)?null:e;if($CLJS.y(e))return $CLJS.ZK(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GM,$CLJS.uK],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.Uh($CLJS.MD("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Cx,$CLJS.MV,$CLJS.uK,$CLJS.Bz(a,b),$CLJS.NV,$CLJS.Bz(a,$CLJS.nd(b)),$CLJS.HK,d,$CLJS.OV,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GM,$CLJS.VD],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.yW.v(a,$CLJS.nd(b),$CLJS.fk,k):q5(a,b)}return a};$CLJS.t5=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.rK);b=0>b?$CLJS.D(a)+b:b;return b+1<$CLJS.D(a)?b+1:null};
$CLJS.u5=function(a,b){a=$CLJS.SV.l(a,b,$CLJS.fk,$CLJS.H([$CLJS.VD]));return $CLJS.Wf.j($CLJS.xf,$CLJS.nk.h($CLJS.mk.g(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.JY,null,$CLJS.IY,null],null),null),$CLJS.NJ)),$CLJS.X_.j(a,b,$CLJS.HV(a,b)))};
v5=function(a,b,c,d){var e=$CLJS.R1.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.sG(function(f){return $CLJS.V1.v(a,b,f,d)},c),(e=!$CLJS.Cd(c,null))?(e=$CLJS.R1.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Le(function(f){return $CLJS.R1.g($CLJS.D(f),1)},$CLJS.Pg(c)):e):e):e};
w5=function(a,b){var c=$CLJS.F3.g(a,b),d=$CLJS.bh(0,$CLJS.D(c));return $CLJS.bf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QQ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.VD],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bP],null)],null),function(){return function k(f){return new $CLJS.ie(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.vd(l)){var m=
$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.F(c,x);$CLJS.VD.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,x,$CLJS.VD],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.VD.h(m)instanceof $CLJS.M)l=$CLJS.Hc(l);else return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,u,$CLJS.VD],null),k($CLJS.Hc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ie(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,x,$CLJS.uK],null));v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,u,$CLJS.uK],null),k($CLJS.Hc(l)))}return null}},null,null)}(d)}()]))};
x5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.tF),k=$CLJS.J.g(e,$CLJS.wQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.tG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.tF.h($CLJS.dd(m)),f)&&$CLJS.E.g($CLJS.wQ.h($CLJS.dd(m)),k)&&$CLJS.E.g($CLJS.ed(m),l)},$CLJS.Fl($CLJS.xr,$CLJS.QQ.h($CLJS.HV(a,b)))))};
Hsa=function(a,b,c,d){c=x5(a,b,c);return $CLJS.n(c)?$CLJS.SV.l(a,b,$CLJS.yW,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.QQ,c,2,1],null),$CLJS.mk.g(function(e){return $CLJS.W0($CLJS.bl,e)},$CLJS.Fk),d])):a};Isa=function(a,b,c){c=x5(a,b,c);return $CLJS.n(c)?$CLJS.SV.l(a,b,s5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QQ],null),$CLJS.Bz($CLJS.HV(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QQ,c],null)),b])):a};
A5=function(a,b,c,d,e,f){var k=$CLJS.SV.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.r5(e);return $CLJS.lk.g(a,k)?$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function(m,t){try{if($CLJS.ud(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.bP))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.AV(e);K=y5.W?y5.W(k,b,c,$CLJS.qA,$CLJS.N,K):y5.call(null,k,b,c,$CLJS.qA,$CLJS.N,K);return z5.v?z5.v(K,b,c,l):z5.call(null,K,b,c,l)}()],null);throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.ce(u,$CLJS.cF))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=y5.W?y5.W(k,b,c,$CLJS.cF,$CLJS.N,l):y5.call(null,k,b,c,$CLJS.cF,$CLJS.N,l);return z5.v?z5.v(S,b,c,l):z5.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{if($CLJS.ud(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.bF))return new $CLJS.P(null,
1,5,$CLJS.Q,[z5.v?z5.v(k,b,c,l):z5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.ce(x,$CLJS.VD))return new $CLJS.P(null,1,5,$CLJS.Q,[z5.v?z5.v(k,b,c,l):z5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){var A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.ud(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ce(C,$CLJS.GM))try{var G=$CLJS.F(t,2);if($CLJS.ce(G,$CLJS.VD))return new $CLJS.P(null,1,5,$CLJS.Q,[z5.v?z5.v(k,b,c,l):z5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){A=X;if(A===$CLJS.Z)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw X;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.xf,d)))):k};
y5=function(a,b,c,d,e,f){var k=$CLJS.HV(a,b),l=$CLJS.df(function(t){var u=$CLJS.Bz(k,t);return $CLJS.n(u)?$CLJS.El(function(v){return $CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function G(A,C){try{if($CLJS.ud(C)&&3===$CLJS.D(C))try{var K=$CLJS.F(C,0);if($CLJS.E.g(K,d))try{var S=$CLJS.F(C,1);if($CLJS.od(e)||$CLJS.V0($CLJS.Wg(e),$CLJS.Wg(S)))try{var X=$CLJS.F(C,2);if($CLJS.E.g(X,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[t,v],null)],null);throw $CLJS.Z;}catch(da){if(da instanceof Error){var T=
da;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){T=da;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){T=da;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){T=da;if(T===$CLJS.Z)return $CLJS.CU(G,A,C);throw T;}throw da;}}($CLJS.xf,v))))},u):null},$CLJS.H([w5(a,b)])),m=$CLJS.Ve($CLJS.ac($CLJS.xf));a=$CLJS.cb(function(t,u){var v=$CLJS.I(u,0,
null);u=$CLJS.I(u,1,null);return A5(t,b,c,v,u,function(x,A,C){try{return s5(x,A,C,b)}catch(K){if(K instanceof Error){A=K;var G=$CLJS.O($CLJS.Kl(A));C=$CLJS.J.g(G,$CLJS.Cx);G=$CLJS.J.g(G,$CLJS.NV);if($CLJS.E.g(C,$CLJS.MV))return m.nd(null,$CLJS.te(m.Lb(null),G)),x;throw A;}throw K;}})},a,l);return $CLJS.cb(function(t,u){return $CLJS.B5.j?$CLJS.B5.j(t,b,u):$CLJS.B5.call(null,t,b,u)},a,$CLJS.cc($CLJS.q(m)))};
z5=function(a,b,c,d){b=$CLJS.t5(c,b);if($CLJS.n(b)){var e=$CLJS.HV(c,b);c=$CLJS.Me(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.NJ),l=$CLJS.J.g(f,$CLJS.E0);return $CLJS.E.g($CLJS.yY,k)&&$CLJS.E.g(d,l)?$CLJS.MY.h(f):null},$CLJS.Z_.j(c,b,e));return $CLJS.n(c)?y5(a,b,a,$CLJS.nF,$CLJS.N,c):a}return a};
D5=function(a,b,c,d,e){var f=$CLJS.tV.h(c),k=$CLJS.HV(a,b);c=$CLJS.tG(function(t){var u=$CLJS.Bz(k,t);if($CLJS.n(u)){var v=$CLJS.r5(f);return $CLJS.n($CLJS.Me($CLJS.mk.j($CLJS.Tg([v]),$CLJS.AD,$CLJS.dd),u))?t:null}return null},w5(a,b));var l=(d=$CLJS.E.g($CLJS.KF,d))?$CLJS.tV.h(e):null;e=d?function(t,u,v){return Gsa(t,u,v,l)}:function(t,u,v){return s5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bF],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.ed(f),$CLJS.ed(l))?
Hsa(a,b,f,$CLJS.Gl($CLJS.dd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wQ,$CLJS.tF],null))):m?Isa(a,b,f):a;return $CLJS.n(c)?(a=A5(a,b,a,c,f,e),$CLJS.C5.h?$CLJS.C5.h(a):$CLJS.C5.call(null,a)):a};E5=function(a){return $CLJS.JD(a,$CLJS.R,$CLJS.H([$CLJS.AD,$CLJS.p.h($CLJS.LD())]))};
Jsa=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.qA))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return E5(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.$V(l,f,k);throw u;
}throw v;}}($CLJS.xf,d)}(a)};Ksa=function(a,b,c){var d=function(){var l=$CLJS.AV(c);return $CLJS.n(l)?l:$CLJS.U.h($CLJS.ID(c))}(),e=E5($CLJS.BV(c,d));a=$CLJS.pk.j(a,$CLJS.bP,function(l){return $CLJS.ok.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.AV(b),k=$CLJS.pi.h($CLJS.ID(c));return Jsa(a,f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,new $CLJS.h(null,1,[$CLJS.pi,k],null),d],null))};
F5=function(a,b,c){return $CLJS.wU(function(d){return $CLJS.E.g(d,$CLJS.r5(b))?$CLJS.r5(c):d},$CLJS.n($CLJS.AV(b))?Ksa(a,b,c):$CLJS.wU(function(d){return $CLJS.E.g(d,b)?c:d},a))};Lsa=function(a,b,c){a=$CLJS.X_.j(a,b,$CLJS.HV(a,b));b=$CLJS.X_.j(c,b,$CLJS.HV(c,b));return $CLJS.ff(function(d){return $CLJS.lk.g($CLJS.z(d),$CLJS.dd(d))},$CLJS.cf.j($CLJS.xr,a,b))};
Msa=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.MY.h(d),f=$CLJS.sV($CLJS.R.j(c,$CLJS.NJ,$CLJS.yY));return $CLJS.cf.g(function(k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,E5(f)],null)},$CLJS.y($CLJS.ff($CLJS.Sa,function t(l,m){try{if($CLJS.ud(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.ce(u,$CLJS.nF))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.P(null,1,5,$CLJS.Q,[m],null);throw $CLJS.Z;}catch(A){if(A instanceof Error){var x=A;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)return $CLJS.CU(t,l,m);throw x;}throw A;}}($CLJS.xf,$CLJS.HV(a,b)))))};Nsa=function(a,b,c){var d=$CLJS.pi.h($CLJS.ID(c));$CLJS.n(d)||(d=$CLJS.O0.h?$CLJS.O0.h(c):$CLJS.O0.call(null,c),d=$CLJS.Ra(d)||!$CLJS.zV(c));if($CLJS.n(d))return c;a=$CLJS.T_.j(a,b,c);return $CLJS.JD(c,$CLJS.R,$CLJS.H([$CLJS.pi,a]))};
Osa=function(a,b){a=$CLJS.AV(a);return $CLJS.n($CLJS.n(a)?$CLJS.Ra($CLJS.AV(b))&&$CLJS.Ra($CLJS.U.h($CLJS.ID(b))):a)?$CLJS.BV(b,a):b};
Psa=function(a,b,c,d){d=Nsa(a,b,Osa(c,d));c=$CLJS.SV.l(a,b,F5,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.t5(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.df(function(l,m,t){return function(u){return Msa(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([Lsa(a,e,c)]));c=$CLJS.cb(function(l,m,t,u){return function(v,x){var A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.SV.l(v,u,F5,$CLJS.H([A,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Qsa=function(a,b){b=$CLJS.Rk.h(b);if($CLJS.E.g($CLJS.z(b),$CLJS.rK)){var c=$CLJS.dd(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Me(function(k){return $CLJS.Le($CLJS.ml,$CLJS.cf.j($CLJS.E,k,d))?k:null},w5(a,c)):f}();return $CLJS.n(e)?$CLJS.tk.j(b,0,$CLJS.D(e)+2+1):null}return null};
Rsa=function(a,b,c,d){for(var e=Psa(a,b,c,d);;){var f=$CLJS.IX($CLJS.NK,e),k=$CLJS.Gk.h($CLJS.El(function(l){return function(m){return Qsa(l,m)}}(e,f),$CLJS.Hr.h(f)));if($CLJS.y(k))e=$CLJS.cb(function(){return function(l,m){try{return $CLJS.G5.j(l,$CLJS.dd(m),$CLJS.Bz(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.Kl(t)),v=$CLJS.J.g(u,$CLJS.Cx);u=$CLJS.J.g(u,$CLJS.NV);if($CLJS.E.g(v,$CLJS.MV))return m=$CLJS.dd(m),$CLJS.B5.j?$CLJS.B5.j(l,m,u):$CLJS.B5.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?D5(a,b,c,$CLJS.KF,d):e}};H5=function(a,b){return $CLJS.I1(a,$CLJS.nF)&&$CLJS.E.g($CLJS.j1(a),b)};Ssa=function(a,b,c){return function k(e,f){try{if(H5(f,b))return $CLJS.k3(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.$V(k,e,f);throw l;}throw m;}}($CLJS.xf,a)};
Tsa=function(a,b,c){var d=$CLJS.GM.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.ZD],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.lk.g(e,c):b)){var f=$CLJS.KV();$CLJS.f3(f,$CLJS.cf.g($CLJS.ZD,d));c=f(c);return Ssa($CLJS.ZK(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,b,$CLJS.ZD],null),c),e,c)}return a};
Usa=function(a,b,c){if($CLJS.Ad(c))return c;var d=function(){var e=$CLJS.Tg([c]);return"string"===typeof c?$CLJS.mk.g(e,$CLJS.ZD):e}();return $CLJS.Me(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Fl($CLJS.xr,$CLJS.GM.h($CLJS.HV(a,b))))};
I5=function(a,b,c,d){b=$CLJS.bt.g($CLJS.Wg($CLJS.Z_.j(b,c,$CLJS.HV(b,c))),$CLJS.Wg($CLJS.Z_.j(a,c,$CLJS.HV(a,c))));return $CLJS.cb(function(e,f){return $CLJS.Ne.M(y5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Vsa=function(a,b,c){a=I5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,new $CLJS.h(null,1,[$CLJS.wO,$CLJS.fZ.h(d)],null),$CLJS.Ki.h(d)],null)});c=$CLJS.t5(a,c);return $CLJS.n(c)?I5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,$CLJS.N,$CLJS.MY.h(d)],null)}):a};
Wsa=function(a,b,c){return $CLJS.Ad(c)?$CLJS.Bz($CLJS.HV(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,c,$CLJS.ZD],null)):$CLJS.sd(c)?$CLJS.ZD.h(c):c};
J5=function(a,b,c,d){var e=Wsa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.SV)(a,b,function(k){var l=$CLJS.GM.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.HD(k,$CLJS.GM,l)});return(0,$CLJS.SV)(f,b,function(k){return $CLJS.VV(k,$CLJS.GM,function(l){return $CLJS.ok.g(function(m){return $CLJS.s3(f,b,m)},l)})})}(),c=Vsa(c,a,b),$CLJS.C5.h?$CLJS.C5.h(c):$CLJS.C5.call(null,c)):a};
Xsa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function f(d,e){try{if(H5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.CU(f,d,e);throw k;}throw l;}}($CLJS.xf,a))))};Ysa=function(a,b){var c=$CLJS.VD.h($CLJS.HV(a,b));if($CLJS.n(c)){var d=$CLJS.Ra($CLJS.Me($CLJS.mk.g($CLJS.HM,$CLJS.ID),c));return d?v5(a,b,c,$CLJS.u5(a,b)):d}return c};
Zsa=function(a,b){var c=$CLJS.HV(a,b),d=$CLJS.n(Ysa(a,b))?$CLJS.SV.l(a,b,$CLJS.fk,$CLJS.H([$CLJS.VD])):a;return $CLJS.n($CLJS.GM.h(c))?$CLJS.SV.l(d,b,$CLJS.pk,$CLJS.H([$CLJS.GM,$CLJS.Qe($CLJS.ok,function(e){var f=$CLJS.VD.h(e);var k=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.wx,null,$CLJS.cz,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(v5(a,b,$CLJS.VD.h(e),$CLJS.X_.j(a,b,$CLJS.R.j(e,$CLJS.VD,$CLJS.wx))))?$CLJS.R.j(e,$CLJS.VD,$CLJS.wx):e})])):d};
$CLJS.C5=function(a){return $CLJS.cb(Zsa,a,$CLJS.bh(0,$CLJS.D($CLJS.rK.h(a))))};$sa=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.K5=new $CLJS.M("lib","hide-bin-bucket?","lib/hide-bin-bucket?",-701033945);var L5;$CLJS.G5=function(){function a(d,e,f){return $CLJS.sd(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.mK)?$CLJS.B5.j?$CLJS.B5.j(d,e,f):$CLJS.B5.call(null,d,e,f):D5(d,e,f,$sa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();L5=$CLJS.qE.h($CLJS.aE);
$CLJS.N5=function(){function a(d,e,f,k){if($CLJS.sd(f)&&$CLJS.E.g($CLJS.Vs.h(f),$CLJS.mK))d=$CLJS.M5.v?$CLJS.M5.v(d,e,f,k):$CLJS.M5.call(null,d,e,f,k);else{var l=L5.h?L5.h(f):L5.call(null,f);l=$CLJS.n(l)?L5.h?L5.h(k):L5.call(null,k):l;d=$CLJS.n(l)?Rsa(d,e,f,k):D5(d,e,f,$CLJS.KF,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.ata=function(){function a(d,e,f,k){f=Usa(d,e,f);return $CLJS.n(f)?$CLJS.SV.l(d,e,Tsa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.B5=function(){function a(d,e,f){try{return J5(d,e,f,function(u,v){return $CLJS.Be($CLJS.z0(function(x){return!($CLJS.E.g($CLJS.ZD.h(x),v)||Xsa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.Kl(k)),m=$CLJS.J.g(l,$CLJS.HK),t=$CLJS.J.g(l,$CLJS.Cx);l=$CLJS.J.g(l,$CLJS.NV);if($CLJS.E.g(t,$CLJS.MV))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.M5=function(){function a(d,e,f,k){return null==k?$CLJS.B5.j(d,e,f):J5(d,e,f,function(l,m){return $CLJS.ok.g(function(t){return $CLJS.E.g($CLJS.ZD.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();