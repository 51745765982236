var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var opa,ppa,qpa,rpa,spa,w0;
$CLJS.t0=function(){function a(e,f){if($CLJS.Ra(f))return"";e=$CLJS.yz(e);var k=$CLJS.mh.h(f);switch(k instanceof $CLJS.M?k.S:null){case "default":return $CLJS.AU("Default period","Default periods",e);case "millisecond":return $CLJS.AU("Millisecond","Milliseconds",e);case "second":return $CLJS.AU("Second","Seconds",e);case "minute":return $CLJS.AU("Minute","Minutes",e);case "hour":return $CLJS.AU("Hour","Hours",e);case "day":return $CLJS.AU("Day","Days",e);case "week":return $CLJS.AU("Week","Weeks",
e);case "month":return $CLJS.AU("Month","Months",e);case "quarter":return $CLJS.AU("Quarter","Quarters",e);case "year":return $CLJS.AU("Year","Years",e);case "minute-of-hour":return $CLJS.AU("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.AU("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.AU("Day of week","Days of week",e);case "day-of-month":return $CLJS.AU("Day of month","Days of month",e);case "day-of-year":return $CLJS.AU("Day of year","Days of year",e);case "week-of-year":return $CLJS.AU("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.AU("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.AU("Quarter of year","Quarters of year",e);default:return f=$CLJS.Ns($CLJS.Xg(f),/-/),e=$CLJS.y(f),f=$CLJS.z(e),e=$CLJS.B(e),$CLJS.hs(" ",$CLJS.ae($CLJS.vz(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.u0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mk,$CLJS.gB],null),$CLJS.Mk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.XA(f)},e,a,b,c,d)}();opa=$CLJS.Se($CLJS.N);ppa=$CLJS.Se($CLJS.N);
qpa=$CLJS.Se($CLJS.N);rpa=$CLJS.Se($CLJS.N);spa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mk,$CLJS.gB],null),$CLJS.Mk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.v0=new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.XA,spa,opa,ppa,qpa,rpa);$CLJS.v0.m(null,$CLJS.Oh,function(){return null});$CLJS.v0.m(null,$CLJS.cG,function(a){return $CLJS.di.h(a)});w0=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.WF,null,$CLJS.ZF,null,$CLJS.$F,null,$CLJS.XF,null],null),null);
$CLJS.tpa=$CLJS.Wf.j($CLJS.xf,$CLJS.mk.g($CLJS.nk.h(w0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.cG,$CLJS.di,a],null);return $CLJS.E.g(a,$CLJS.Nj)?$CLJS.R.j(b,$CLJS.Oh,!0):b})),$CLJS.nG);$CLJS.upa=$CLJS.ok.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.cG,$CLJS.di,a],null);return $CLJS.E.g(a,$CLJS.Rj)?$CLJS.R.j(b,$CLJS.Oh,!0):b},$CLJS.iG);
$CLJS.vpa=$CLJS.Wf.j($CLJS.xf,$CLJS.mk.g($CLJS.nk.h(w0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.cG,$CLJS.di,a],null);return $CLJS.E.g(a,$CLJS.Rj)?$CLJS.R.j(b,$CLJS.Oh,!0):b})),$CLJS.oG);$CLJS.N_.m(null,$CLJS.cG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.di);return $CLJS.t0.h(a)});
$CLJS.V_.m(null,$CLJS.cG,function(a,b,c){var d=$CLJS.Fk.l;a=$CLJS.m_.j(a,b,c);b=$CLJS.$z($CLJS.v0.h(c));var e=$CLJS.v0.h(c);e=$CLJS.Cd($CLJS.qG,e)&&!$CLJS.Cd($CLJS.pG,e);return d.call($CLJS.Fk,$CLJS.H([new $CLJS.h(null,3,[$CLJS.oD,a,$CLJS.L_,b,$CLJS.v_,e],null),$CLJS.Gl(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oh,$CLJS.u_],null))]))});
$CLJS.x0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mk,$CLJS.gB],null),$CLJS.Mk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();$CLJS.x0.m(null,$CLJS.Oh,function(){return $CLJS.Sg});
$CLJS.y0=function(){function a(d,e,f){return $CLJS.x0.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();