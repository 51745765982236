var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.types.constants.js");require("./metabase.lib.util.js");require("./metabase.types.js");
'use strict';var OZ;$CLJS.EZ=function(a){return $CLJS.DZ($CLJS.zZ,a)};$CLJS.FZ=function(a){return $CLJS.DZ($CLJS.oZ,a)};$CLJS.GZ=function(a){return $CLJS.DZ($CLJS.lZ,a)};$CLJS.HZ=function(a){return $CLJS.DZ($CLJS.nZ,a)};$CLJS.IZ=function(a){return $CLJS.DZ($CLJS.Bj,a)};$CLJS.JZ=function(a){return $CLJS.DZ($CLJS.rZ,a)};$CLJS.KZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.CC)};$CLJS.LZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.Xh)};$CLJS.MZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.ai)};
$CLJS.NZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.vi)};OZ=function(a){return $CLJS.Dz($CLJS.pi.h(a),$CLJS.rj)};$CLJS.PZ=function(a){var b=OZ(a);return b?(a=$CLJS.vj.h(a),null==a||$CLJS.Dz(a,$CLJS.rj)):b};$CLJS.QZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.ui)};$CLJS.RZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.mC)};$CLJS.SZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.NC)};$CLJS.TZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.HC)};
$CLJS.UZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.cj)};$CLJS.VZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.WB)};$CLJS.WZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.IC)};$CLJS.XZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.QB)};$CLJS.YZ=function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.Xh)||$CLJS.Dz($CLJS.vj.h(a),$CLJS.ai)};$CLJS.DZ=function DZ(a,b){a=$CLJS.CZ.h?$CLJS.CZ.h(a):$CLJS.CZ.call(null,a);var d=$CLJS.sd(b)&&$CLJS.Ra($CLJS.pi.h(b))?$CLJS.R.j(b,$CLJS.pi,$CLJS.tA.h(b)):b;return null==d?!1:$CLJS.n($CLJS.Me(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=function(){var l=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.vj,null,$CLJS.pi,null],null),null);return l.h?l.h(f):l.call(null,f)}();return $CLJS.n(k)?$CLJS.Me(function(l){return $CLJS.Dz(f.h?f.h(d):f.call(null,d),l)},e):k},a))?!0:$CLJS.n($CLJS.Me(function(e){return DZ.g?
DZ.g(e,d):DZ.call(null,e,d)},$CLJS.hZ.h(a)))?!1:$CLJS.n($CLJS.Me(function(e){return DZ.g?DZ.g(e,d):DZ.call(null,e,d)},$CLJS.uZ.h(a)))?!0:!1};
module.exports={URL_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.NB)},string_like_QMARK_:$CLJS.HZ,creation_time_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.OB)},any_QMARK_:function(){return!0},description_QMARK_:$CLJS.KZ,foreign_key_QMARK_:$CLJS.LZ,metric_QMARK_:function(a){return $CLJS.lk.g($CLJS.NJ.h(a),$CLJS.cK)&&$CLJS.IZ(a)},address_QMARK_:$CLJS.QZ,isa_QMARK_:function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.pi);var d=$CLJS.J.g(c,$CLJS.tA);c=$CLJS.J.g(c,$CLJS.vj);return $CLJS.Dz($CLJS.n(a)?
a:d,b)||$CLJS.Dz(c,b)},boolean_QMARK_:function(a){return $CLJS.DZ($CLJS.tZ,a)},category_QMARK_:$CLJS.JZ,email_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.hC)},creation_date_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.$B)},numeric_QMARK_:$CLJS.FZ,zip_code_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.MB)},field_type_QMARK_:$CLJS.DZ,longitude_QMARK_:$CLJS.WZ,time_QMARK_:function(a){return $CLJS.Dz($CLJS.pi.h(a),$CLJS.Qk)},id_QMARK_:$CLJS.YZ,temporal_QMARK_:$CLJS.EZ,state_QMARK_:$CLJS.SZ,
entity_name_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.Ti)},city_QMARK_:$CLJS.RZ,string_QMARK_:$CLJS.GZ,title_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.DC)},field_type:function(a){return $CLJS.tG(function(b){return $CLJS.DZ(b,a)},new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.zZ,$CLJS.pZ,$CLJS.yZ,$CLJS.sZ,$CLJS.wZ,$CLJS.tZ,$CLJS.lZ,$CLJS.nZ,$CLJS.oZ],null))},latitude_QMARK_:$CLJS.VZ,location_QMARK_:function(a){return $CLJS.DZ($CLJS.pZ,a)},primary_key_QMARK_:$CLJS.MZ,dimension_QMARK_:function(a){return $CLJS.n(a)?
$CLJS.lk.g($CLJS.NJ.h(a),$CLJS.kK)&&!$CLJS.KZ(a):a},creation_timestamp_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.DB)},image_URL_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.KC)},coordinate_QMARK_:$CLJS.UZ,json_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.mi)},avatar_URL_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.wC)},summable_QMARK_:$CLJS.IZ,scope_QMARK_:function(a){return $CLJS.DZ($CLJS.Ji,a)},country_QMARK_:$CLJS.TZ,string_or_string_like_QMARK_:function(a){return $CLJS.GZ(a)||
$CLJS.HZ(a)},integer_QMARK_:function(a){return $CLJS.DZ($CLJS.AZ,a)},structured_QMARK_:$CLJS.NZ,has_latitude_and_longitude_QMARK_:function(a){return $CLJS.Le(function(b){return $CLJS.Me(b,a)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VZ,$CLJS.WZ],null))},comment_QMARK_:$CLJS.XZ,currency_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.KB)},xml_QMARK_:function(a){return $CLJS.Dz($CLJS.vj.h(a),$CLJS.Jj)},numeric_base_type_QMARK_:OZ,primary_key_pred:function(a){return function(b){var c=$CLJS.MZ(b);return $CLJS.n($CLJS.IV(a))?
c:c&&$CLJS.E.g($CLJS.aK.h(b),a)}},date_without_time_QMARK_:function(a){return $CLJS.Dz($CLJS.pi.h(a),$CLJS.Kk)},number_QMARK_:$CLJS.PZ};