var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var GX,Ona,Pna,Qna,JX,Sna,Tna,Una,Vna,LX,MX,NX,PX,Wna,QX,RX,Xna,Yna,Zna,SX,Rna,TX,UX,$na,WX;GX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.HX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.IX=function(a,b){return $CLJS.TK(a)(b,$CLJS.xf,$CLJS.xf)};
Ona=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.cF,b),$CLJS.Wr(2,2,c))};Pna=function(a,b){var c=$CLJS.Hl(a,$CLJS.IP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.gs.g?$CLJS.gs.g(c,b):$CLJS.gs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.IP,b)}return a};Qna=function(a){return $CLJS.bf.g($CLJS.OU(a),$CLJS.PU(a))};
JX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dr,null,$CLJS.Vk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?GX(2,c):GX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.yW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.ud(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.nk.h($CLJS.bl),l):$CLJS.sd(k)?$CLJS.Jz($CLJS.Sa,l):l}):$CLJS.fk.g(a,f)};
Sna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return JX(b,Rna,d)},a,Qna(a))};
Tna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.rK.h(a),b);a:{var d=c,e=$CLJS.RD;$CLJS.RD=!0;try{for(var f=$CLJS.xf;;){var k=$CLJS.z($CLJS.cf.g($CLJS.HX($CLJS.Ui,$CLJS.Rk),$CLJS.ff($CLJS.mk.j(KX,$CLJS.z,$CLJS.Rk),$CLJS.Hr.h($CLJS.IX($CLJS.nV,d)))));if($CLJS.n(k)){var l=k,m=$CLJS.I(l,0,null),t=$CLJS.I(l,1,null),u=JX(d,m,t);l=$CLJS.xh;var v=l.l,x=m;var A=$CLJS.n(x)?x:$CLJS.YK($CLJS.IX($CLJS.nV,d));var C=v.call(l,$CLJS.H([A]));console.warn("Clean: Removing bad clause due to error!",t,C,$CLJS.xV($CLJS.z($CLJS.Zs(d,
u))));if($CLJS.E.g(u,d)){var G=d;break a}l=u;var K=$CLJS.Yd.g(f,new $CLJS.P(null,2,5,$CLJS.Q,[m,t],null));d=l;f=K}else{G=d;break a}}}finally{$CLJS.RD=e}G=void 0}e=Sna(G);if($CLJS.E.g(c,e)){if($CLJS.E.g(b,$CLJS.D($CLJS.rK.h(a))-1))return a;b+=1}else a=$CLJS.pk.M(a,$CLJS.rK,$CLJS.R,b,e)}};Una=function(a){var b=$CLJS.KV();return $CLJS.ok.g(function(c){return $CLJS.E.g($CLJS.ZD.h(c),"__join")?$CLJS.pk.j(c,$CLJS.ZD,b):c},a)};
Vna=function(a){return"string"===typeof $CLJS.hO.h(a)?$CLJS.fk.g($CLJS.R.j(a,$CLJS.QU,$CLJS.IV($CLJS.hO.h(a))),$CLJS.hO):a};LX=function(){return $CLJS.nk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};MX=function(a){return $CLJS.Wf.j($CLJS.N,LX(),a)};
NX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.mk.g(LX(),$CLJS.nk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.pi)})),a))};
PX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.OX),c);a=$CLJS.E.g(a,$CLJS.WE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Be(NX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xL,a,b],null):a};Wna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.mk.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.FA)}),$CLJS.cf.h($CLJS.OX)),$CLJS.EV.h(a))};
QX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.rK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.OX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.KP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.UN,Wna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.qU.h(c)],null)},null,a));return $CLJS.n($CLJS.lP.h(a))?$CLJS.BU(a,new $CLJS.h(null,1,[$CLJS.lP,$CLJS.JE],null)):a};
RX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.VV(a,b,$CLJS.mk.g($CLJS.OX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.VV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kr],null),$CLJS.cf.h($CLJS.OX),e)}):d;return $CLJS.BU(a,$CLJS.Ae([b,c]))};Xna=function(a){var b=$CLJS.QU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.fk.g(a,$CLJS.QU),$CLJS.hO,["card__",$CLJS.p.h(b)].join("")):a};
Yna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Zna=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);SX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);TX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
UX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.VX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$na=new $CLJS.M(null,"m","m",1632677161);WX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var KX,aoa,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa;$CLJS.XX=$CLJS.N;$CLJS.YX=$CLJS.N;KX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.kV,null,$CLJS.VD,null,$CLJS.GM,null,$CLJS.bP,null,$CLJS.bF,null,$CLJS.QQ,null,$CLJS.cF,null],null),null);aoa=$CLJS.Se($CLJS.N);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mk,$CLJS.gB],null),$CLJS.Mk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.ZX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.XA,eoa,aoa,boa,coa,doa);$CLJS.ZX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.sd(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.rV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.ZX),d))}else b=a;return b});$CLJS.ZX.m(null,$CLJS.jV,function(a){return a});
$CLJS.ZX.m(null,$CLJS.XU,function(a){var b=$CLJS.ZX.h($CLJS.cF.h(a)),c=$CLJS.Be($CLJS.ok.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.BV($CLJS.ZX.h(t),u)},$CLJS.bP.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Jl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Jl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.AD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.YX,k=$CLJS.XX;$CLJS.YX=d;$CLJS.XX=e;try{var l=Ona(Vna(a),b,$CLJS.H([$CLJS.bP,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ra($CLJS.J.g(t,u))?t:$CLJS.pk.j(t,u,$CLJS.ZX)},l,$CLJS.gk.l(KX,$CLJS.cF,$CLJS.H([$CLJS.bP])));return $CLJS.n($CLJS.GM.h(m))?$CLJS.pk.j(m,$CLJS.GM,Una):m}finally{$CLJS.XX=k,$CLJS.YX=f}});$CLJS.ZX.m(null,$CLJS.dV,function(a){return Pna(a,function(b){return $CLJS.VV(b,$CLJS.GE,$CLJS.ZX)})});
$CLJS.ZX.m(null,$CLJS.mK,function(a){a=$CLJS.pk.j($CLJS.pk.j(a,$CLJS.uK,$CLJS.ZX),$CLJS.rK,$CLJS.ZX);var b=$CLJS.n($CLJS.VD.h(a))?$CLJS.pk.j(a,$CLJS.VD,function(c){return $CLJS.nl(c)?$CLJS.ok.g($CLJS.ZX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ra($CLJS.ZD.h(a))?$CLJS.R.j(b,$CLJS.ZD,"__join"):b});$CLJS.ZX.m(null,$CLJS.nA,function(a){return $CLJS.ok.g($CLJS.ZX,a)});
$CLJS.ZX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Ui.h(a))?Tna($CLJS.R.j($CLJS.pk.j($CLJS.FV(a),$CLJS.rK,function(b){return $CLJS.ok.g($CLJS.ZX,b)}),$CLJS.VX,!0)):$CLJS.gs(a,$CLJS.ZX)});$CLJS.ZX.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,a,b],null))});
$CLJS.ZX.m(null,$CLJS.gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.BU(a,new $CLJS.h(null,3,[$CLJS.cC,$CLJS.tA,$CLJS.GN,$CLJS.vj,$CLJS.YQ,$CLJS.vD],null));var c=$CLJS.R.j;var d=$CLJS.pi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.PD(b));a=c.call($CLJS.R,a,$CLJS.pi,d);return $CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,a,b],null))});
$CLJS.ZX.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,$CLJS.fk.g(c,$CLJS.Oh),$CLJS.ok.g($CLJS.ZX,b)],null);b=$CLJS.rV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.ZX.h(a)):b});$CLJS.ZX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.ZX.m(null,$CLJS.cF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.YX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.aR,a],null));return $CLJS.rV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.ZX.m(null,$CLJS.xL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.ZX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Fk.l($CLJS.H([c,a]))],null),d)});foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mk,$CLJS.gB],null),$CLJS.Mk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.OX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.XA,joa,foa,goa,hoa,ioa);
$CLJS.OX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.sd(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.OX,d);a=NX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.OX,$CLJS.ae(a,d)))}else a=$CLJS.sd(a)?$CLJS.gs(MX(a),$CLJS.OX):
a;return a});for(var $X=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[WX,UX],null)),aY=null,bY=0,cY=0;;)if(cY<bY){var koa=aY.X(null,cY);$CLJS.tE(koa,TX);cY+=1}else{var dY=$CLJS.y($X);if(dY){var eY=dY;if($CLJS.vd(eY)){var fY=$CLJS.ic(eY),loa=$CLJS.jc(eY),moa=fY,noa=$CLJS.D(fY);$X=loa;aY=moa;bY=noa}else{var ooa=$CLJS.z(eY);$CLJS.tE(ooa,TX);$X=$CLJS.B(eY);aY=null;bY=0}cY=0}else break}
for(var gY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.hF,$CLJS.wF,$CLJS.SE,$CLJS.Ej,$CLJS.AF,$CLJS.ti,$CLJS.ZE,$CLJS.uF,$CLJS.BF,$CLJS.zF,$CLJS.GF],null)),hY=null,iY=0,jY=0;;)if(jY<iY){var poa=hY.X(null,jY);$CLJS.tE(poa,WX);jY+=1}else{var kY=$CLJS.y(gY);if(kY){var lY=kY;if($CLJS.vd(lY)){var mY=$CLJS.ic(lY),qoa=$CLJS.jc(lY),roa=mY,soa=$CLJS.D(mY);gY=qoa;hY=roa;iY=soa}else{var toa=$CLJS.z(lY);$CLJS.tE(toa,WX);gY=$CLJS.B(lY);hY=null;iY=0}jY=0}else break}
for(var nY=$CLJS.y($CLJS.tf([$CLJS.Mq,$CLJS.Zr,$CLJS.Nq,$CLJS.HF,$CLJS.WE,$CLJS.qF,$CLJS.NE,$CLJS.JF,$CLJS.EE,$CLJS.aF,$CLJS.IE,$CLJS.lF,$CLJS.sF,$CLJS.KE,$CLJS.ZA,$CLJS.$A,$CLJS.Fx,$CLJS.YH,$CLJS.cI,$CLJS.SH,$CLJS.aB,$CLJS.XH,$CLJS.QH,$CLJS.fI,$CLJS.kI,$CLJS.hI,$CLJS.WH,$CLJS.mI,$CLJS.bB,$CLJS.eB,$CLJS.ME,$CLJS.FE,$CLJS.KF,$CLJS.YG,$CLJS.pF,$CLJS.Pw,$CLJS.CF,$CLJS.EF,$CLJS.LF,$CLJS.js,$CLJS.ps],!0)),oY=null,pY=0,qY=0;;)if(qY<pY){var uoa=oY.X(null,qY);$CLJS.tE(uoa,UX);qY+=1}else{var rY=$CLJS.y(nY);
if(rY){var sY=rY;if($CLJS.vd(sY)){var tY=$CLJS.ic(sY),voa=$CLJS.jc(sY),woa=tY,xoa=$CLJS.D(tY);nY=voa;oY=woa;pY=xoa}else{var yoa=$CLJS.z(sY);$CLJS.tE(yoa,UX);nY=$CLJS.B(sY);oY=null;pY=0}qY=0}else break}$CLJS.OX.m(null,TX,function(a){return PX(a)});$CLJS.OX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.mk.g(LX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.OX.h(b)],null)})),a)});
$CLJS.OX.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.sd(b))try{var d=NX(b);b=$CLJS.Q;var e=$CLJS.XX,f=$CLJS.J.j(e,c,SX);if($CLJS.E.g(f,SX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[$na,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.cF,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xL,k,d],null):k}catch(l){throw c=l,d=$CLJS.vU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.OX.m(null,$CLJS.nA,function(a){return $CLJS.ok.g($CLJS.OX,a)});
$CLJS.OX.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,$CLJS.OX.h(a),NX(b)],null)});
$CLJS.OX.m(null,$CLJS.gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=NX($CLJS.BU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.cC,$CLJS.vj,$CLJS.GN,$CLJS.vD,$CLJS.YQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,a,b],null)});
$CLJS.OX.m(null,$CLJS.mK,function(a){var b=MX(a);a=0==$CLJS.ZD.h(a).lastIndexOf("__join",0)?$CLJS.fk.g(b,$CLJS.ZD):b;return $CLJS.Fk.l($CLJS.H([$CLJS.gs($CLJS.fk.l(a,$CLJS.rK,$CLJS.H([$CLJS.uK])),$CLJS.OX),RX($CLJS.Gl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uK],null)),$CLJS.uK,$CLJS.SQ),QX(a)]))});
$CLJS.OX.m(null,$CLJS.XU,function(a){var b=$CLJS.cF.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Jl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Jl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.YX,e=$CLJS.XX;$CLJS.YX=c;$CLJS.XX=b;try{return $CLJS.cb(function(f,k){return $CLJS.VV(f,
k,$CLJS.OX)},RX($CLJS.VV($CLJS.VV(Xna(MX(a)),$CLJS.cF,function(f){return $CLJS.ok.g(PX,f)}),$CLJS.bP,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.OX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AV(C),$CLJS.E.g($CLJS.gj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.OX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AV(x),$CLJS.E.g($CLJS.gj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.kV,$CLJS.RE),$CLJS.gk.l(KX,$CLJS.cF,$CLJS.H([$CLJS.kV,$CLJS.bP])))}finally{$CLJS.XX=e,$CLJS.YX=d}});$CLJS.OX.m(null,$CLJS.dV,function(a){return $CLJS.gs(MX(a),$CLJS.OX)});
$CLJS.OX.m(null,$CLJS.jV,function(a){try{var b=MX(a),c=$CLJS.xM.h(b),d=QX(b),e=$CLJS.E.g($CLJS.Vs.h($CLJS.ed($CLJS.rK.h(a))),$CLJS.dV)?$CLJS.lP:$CLJS.JE;return $CLJS.Fk.l($CLJS.H([$CLJS.fk.l(b,$CLJS.rK,$CLJS.H([$CLJS.xM,$CLJS.VX])),function(){var k=$CLJS.Ae([$CLJS.Ui,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.xM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.vU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.JE,a],null),f);}});
$CLJS.uY=function(){function a(d,e,f){f=$CLJS.FX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.ki,!0])));var k=$CLJS.HV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.cF);k=$CLJS.Wf.j($CLJS.N,$CLJS.Jl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Jl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.YX,t=$CLJS.XX;$CLJS.YX=k;$CLJS.XX=l;try{try{return $CLJS.ZX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.vU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.JE,d,$CLJS.HK,e,Zna,f,Yna,$CLJS.YX],null),u);}}finally{$CLJS.XX=t,$CLJS.YX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();