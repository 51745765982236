var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var UK,VK,WK,nja,oja,$K,pja,cL,dL,eL,fL,qja,gL,hL,jL,kL,XK,lL,rja,mL,sja,nL,oL,pL,tja,qL,rL,uja,sL,vja,tL,uL,wja,wL,xja,yja,zja,yL,zL,AL,BL,CL,DL,EL,FL,Aja,GL,HL,IL,KL,LL,Bja,ML,NL,OL,PL,Cja,QL,RL,SL,TL,Dja,UL,VL,WL,XL,Eja,YL,Fja,ZL,$L,aM,Gja,bM,cM,dM,eM,gM,hM,Hja,iM,jM,Ija,kM,lM,mM,nM,oM,Jja,pM,Kja,qM,rM,tM,Lja,uM,vM,wM,Mja,zM,AM,Nja,Oja,BM,DM,EM,FM,Pja,IM,JM,Qja,Rja,Sja,KM,LM,MM,NM,OM,PM,Tja,QM,Uja,RM,SM,UM,WM,XM,YM,ZM,Vja,Wja,aN,Xja,Yja,bN,cN,dN,eN,fN,Zja,gN,$ja,aka,hN,iN,jN,kN,lN,
bka,cka,dka,mN,eka,nN,fka,pN,qN,gka,sN,tN,uN,vN,hka,yN,ika,jka,zN,AN,BN,kka,CN,DN,EN,FN,lka,HN,IN,JN,KN,LN,mka,MN,NN,nka,ON,PN,QN,RN,SN,TN,VN,oka,WN,pka,XN,YN,ZN,qka,rka,ska,aO,bO,cO,tka,dO,eO,fO,gO,uka,vka,iO,wka,xka,jO,kO,lO,mO,nO,yka,oO,pO,qO,rO,zka,sO,tO,uO,vO,xO,Aka,yO,zO,Bka,AO,BO,CO,Cka,DO,Dka,FO,Eka,GO,HO,IO,JO,KO,LO,MO,Fka,Gka,Hka,NO,Ika,OO,PO,Jka,Kka,QO,RO,SO,Lka,TO,Mka,UO,Nka,Oka,VO,Pka,Qka,Rka,YO,ZO,Ska,$O,aP,cP,Tka,dP,Uka,eP,fP,gP,Vka,hP,iP,jP,kP,Wka,mP,nP,Xka,oP,pP,qP,rP,sP,tP,uP,vP,
Yka,wP,xP,Zka,yP,$ka,zP,AP,BP,ala,CP,DP,bla,cla,EP,FP,GP,dla,ela,HP,fla,gla,hla,JP,ila,LP,jla,kla,MP,NP,lla,mla,nla,OP,PP,QP,RP,SP,TP,UP,ola,VP,WP,pla,XP,qla,rla,sla,YP,ZP,$P,tla,aQ,bQ,ula,cQ,vla,dQ,wla,xla,fQ,gQ,hQ,iQ,jQ,yla,kQ,lQ,zla,mQ,nQ,oQ,pQ,qQ,rQ,sQ,tQ,Ala,uQ,vQ,xQ,yQ,zQ,AQ,BQ,CQ,DQ,EQ,FQ,GQ,Bla,HQ,IQ,JQ,KQ,LQ,Cla,MQ,NQ,OQ,PQ,Dla,RQ,Ela,Fla,TQ,Gla,UQ,Hla,VQ,Ila,Jla,WQ,Kla,XQ,ZQ,$Q,Lla,Mla,dR,eR,Nla,Ola,fR,gR,hR,iR,jR,Pla,kR,lR,nR,oR,Qla;
$CLJS.TK=function(a){var b=$CLJS.Fn.g(a,null),c=$CLJS.pE(b,$CLJS.Br,function(d){return $CLJS.Lm(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Ij,b,$CLJS.gj,k,$CLJS.Hr,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
UK=function(a,b){return $CLJS.sd(a)?$CLJS.J.g(a,b):a};VK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Om($CLJS.Fn.g($CLJS.Ij.h(a),null));if($CLJS.n(b)){var e=UK($CLJS.Es.h(b),c);$CLJS.n(e)?(e=$CLJS.Dp(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:UK($CLJS.ts.h(b),c)}return null};WK=function(a,b){return $CLJS.qd(a)||$CLJS.kl(a)?$CLJS.J.g(a,b):$CLJS.rd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
nja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Ij),d=$CLJS.J.g(a,$CLJS.Ui);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Hr,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.Fs),l=$CLJS.J.j(b,$CLJS.Cs,$CLJS.As),m=VK(a,$CLJS.Fp.h(c),k,b);if($CLJS.n(m))return m;m=VK(a,$CLJS.Tr.h(c),k,b);if($CLJS.n(m))return m;m=VK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=VK(a,function(){var t=$CLJS.rn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=VK(a,$CLJS.Fp.h(c),
l,b);if($CLJS.n(m))return m;m=VK(a,$CLJS.Tr.h(c),l,b);if($CLJS.n(m))return m;d=VK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=VK(a,function(){var t=$CLJS.rn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?VK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?VK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),l,b):f};
oja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Rk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Ij),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.Fs);l=$CLJS.J.j(l,$CLJS.Cs,$CLJS.As);k=$CLJS.Fp.h(k);f=UK($CLJS.Bs.h(k),f);f=$CLJS.n(f)?f:UK($CLJS.Bs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,nja(b,c)],null)};
$CLJS.YK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.gj);a=$CLJS.J.g(b,$CLJS.Hr);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.Ai),f=$CLJS.J.j(d,$CLJS.jda,oja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ai,m);l=e.h?e.h(l):e.call(null,l);return XK(k,c,t,l)},null,a):null};
$K=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.ZC),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.ZC,$CLJS.ZK,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.aL=function(a){return $K($CLJS.Uj,a,function(){return $CLJS.qE.h(a)})};pja=function(){var a=bL;return $K($CLJS.Br,a,function(){var b=$CLJS.qE.h(a),c=$CLJS.TK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
cL=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};dL=function(a){if($CLJS.ud(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,cL(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,cL(c)],null);default:return cL(a)}}else return cL(a)};
eL=function(a,b){var c=$CLJS.ud(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
fL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(eL,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yq,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,dL(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,dL(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Wr(2,2,b))}())],null)};qja=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
gL=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.yi,qja,$CLJS.ts,["valid instance of one of these MBQL clauses: ",$CLJS.hs(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
hL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SK],null)],null)};$CLJS.iL={};jL={};kL={};$CLJS.ZK=function ZK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=ZK.j?ZK.j(k,f,c):ZK.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
XK=function XK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=WK(b,c);f=$CLJS.n(a)?a:$CLJS.rd(b)?$CLJS.xf:$CLJS.td(b)?$CLJS.N:$CLJS.fd(b);return $CLJS.n($CLJS.n(c)?$CLJS.zs.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=WK(f,c),d=XK.v?XK.v(b,l,k,d):XK.call(null,b,l,k,d),$CLJS.pl(c)&&$CLJS.rd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.yd(f)?b:$CLJS.Wf.g($CLJS.fd(f),b)),null==f||$CLJS.kl(f)?$CLJS.R.j(f,c,d):$CLJS.qd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.V,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.sd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),XK.v?XK.v(f,b,c,d):XK.call(null,f,b,c,d)):$CLJS.n($CLJS.zs.h($CLJS.ld(f)))?$CLJS.Yd.g(f,d):$CLJS.ud($CLJS.Be(f))?f:$CLJS.kd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.zs,!0],null))};lL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);rja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
mL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);sja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);nL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);oL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);pL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
tja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);qL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);rL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);uja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);sL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
vja=new $CLJS.M(null,"from","from",1815293044);tL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);uL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);wja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.vL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);wL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
xja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);yja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.xL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);zja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);yL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
zL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);AL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);BL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);CL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);DL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
EL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);FL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Aja=new $CLJS.M(null,"lon-max","lon-max",1590224717);GL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);HL=new $CLJS.r(null,"stddev","stddev",775056588,null);IL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.JL=new $CLJS.M(null,"snippet","snippet",953581994);KL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);LL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Bja=new $CLJS.M(null,"lat-field","lat-field",-830652957);ML=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);NL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
OL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);PL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Cja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);QL=new $CLJS.M("location","country","location/country",1666636202);RL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
SL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);TL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Dja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);UL=new $CLJS.M(null,"unary","unary",-989314568);VL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
WL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);XL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Eja=new $CLJS.M(null,"lon-min","lon-min",-787291357);YL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Fja=new $CLJS.M(null,"match","match",1220059550);ZL=new $CLJS.r(null,"count-where","count-where",2025939247,null);$L=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
aM=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Gja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);bM=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);cM=new $CLJS.r(null,"sum","sum",1777518341,null);dM=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);eM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.fM=new $CLJS.M("date","range","date/range",1647265776);gM=new $CLJS.r(null,"between","between",-1523336493,null);hM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Hja=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);iM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);jM=new $CLJS.r(null,"field","field",338095027,null);Ija=new $CLJS.M(null,"segment-id","segment-id",1810133590);
kM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);lM=new $CLJS.r(null,"not-null","not-null",313812992,null);mM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);nM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);oM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Jja=new $CLJS.M(null,"template-tag","template-tag",310841038);
pM=new $CLJS.M(null,"invalid","invalid",412869516);Kja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);qM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);rM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.sM=new $CLJS.M(null,"context","context",-830191113);tM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Lja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);uM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);vM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);wM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.xM=new $CLJS.M(null,"parameters","parameters",-1229919748);Mja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.yM=new $CLJS.M(null,"card","card",-1430355152);zM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);AM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Nja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Oja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);BM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.CM=new $CLJS.M("date","month-year","date/month-year",1948031290);
DM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);EM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);FM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.GM=new $CLJS.M(null,"joins","joins",1033962699);Pja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.HM=new $CLJS.M(null,"source-field","source-field",933829534);IM=new $CLJS.r(null,"Field","Field",430385967,null);
JM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Qja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Rja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Sja=new $CLJS.M(null,"items","items",1031954938);KM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);LM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
MM=new $CLJS.M(null,"more","more",-2058821800);NM=new $CLJS.M(null,"first-clause","first-clause",-20953491);OM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);PM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Tja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);QM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Uja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);RM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);SM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.TM=new $CLJS.M(null,"widget-type","widget-type",1836256899);UM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.VM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
WM=new $CLJS.r(null,"is-null","is-null",-356519403,null);XM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);YM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);ZM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Vja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.$M=new $CLJS.M(null,"required","required",1807647006);Wja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
aN=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Xja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Yja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);bN=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);cN=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);dN=new $CLJS.M("string","contains","string/contains",1602423827);eN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
fN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Zja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);gN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$ja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);aka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
hN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);iN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);jN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);kN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);lN=new $CLJS.r(null,"share","share",1051097594,null);
bka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);cka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);dka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);mN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);eka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
nN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);fka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.oN=new $CLJS.M(null,"collection","collection",-683361892);pN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);qN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.rN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);gka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);sN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);tN=new $CLJS.r(null,"metric","metric",2049329604,null);uN=new $CLJS.r(null,"concat","concat",-467652465,null);vN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.wN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
hka=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.xN=new $CLJS.M("date","relative","date/relative",25987732);yN=new $CLJS.M("location","city","location/city",-1746973325);ika=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);jka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);zN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);AN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
BN=new $CLJS.M("number","between","number/between",97700581);kka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);CN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);DN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);EN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);FN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.GN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);lka=new $CLJS.M(null,"metric-id","metric-id",-686486942);HN=new $CLJS.r(null,"*","*",345799209,null);IN=new $CLJS.r(null,"+","+",-740910886,null);JN=new $CLJS.r(null,"-","-",-471816912,null);KN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);LN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);mka=new $CLJS.M(null,"question","question",-1411720117);MN=new $CLJS.r(null,"asc","asc",1997386096,null);
NN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);nka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);ON=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);PN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);QN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);RN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);SN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
TN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.UN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);VN=new $CLJS.M("string","ends-with","string/ends-with",302681156);oka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);WN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);pka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
XN=new $CLJS.r(null,"and","and",668631710,null);YN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);ZN=new $CLJS.r(null,"round","round",-645002441,null);qka=new $CLJS.M(null,"to","to",192099007);$CLJS.$N=new $CLJS.M("date","single","date/single",1554682003);rka=new $CLJS.M(null,"action-id","action-id",-1727958578);ska=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);aO=new $CLJS.r(null,"exp","exp",1378825265,null);
bO=new $CLJS.r(null,"Filter","Filter",-424893332,null);cO=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);tka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);dO=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);eO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);fO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
gO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.hO=new $CLJS.M(null,"source-table","source-table",-225307692);uka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);vka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);iO=new $CLJS.r(null,"floor","floor",-772394748,null);wka=new $CLJS.M(null,"middleware","middleware",1462115504);
xka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);jO=new $CLJS.M(null,"requires-features","requires-features",-101116256);kO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);lO=new $CLJS.M(null,"clause-name","clause-name",-996419059);mO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);nO=new $CLJS.r(null,"now","now",-9994004,null);
yka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);oO=new $CLJS.r(null,"not","not",1044554643,null);pO=new $CLJS.r(null,"avg","avg",1837937727,null);qO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);rO=new $CLJS.M(null,"max-results","max-results",-32858165);zka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);sO=new $CLJS.r(null,"case","case",-1510733573,null);
tO=new $CLJS.r(null,"distinct","distinct",-148347594,null);uO=new $CLJS.r(null,"get-second","get-second",-425414791,null);vO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.wO=new $CLJS.M(null,"join-alias","join-alias",1454206794);xO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Aka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
yO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);zO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Bka=new $CLJS.M(null,"original","original",-445386197);AO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);BO=new $CLJS.r(null,"abs","abs",1394505050,null);CO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Cka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
DO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Dka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.EO=new $CLJS.M(null,"date","date",-1463434462);FO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Eka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);GO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
HO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);IO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);JO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);KO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);LO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);MO=new $CLJS.r(null,"or","or",1876275696,null);
Fka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Gka=new $CLJS.M(null,"constraints","constraints",422775616);Hka=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);NO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ika=new $CLJS.M(null,"csv-download","csv-download",2141432084);OO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
PO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Jka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Kka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);QO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);RO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
SO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Lka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);TO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Mka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);UO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Nka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Oka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);VO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Pka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Qka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Rka=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.WO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.XO=new $CLJS.M(null,"database","database",1849087575);YO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);ZO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Ska=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$O=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
aP=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.bP=new $CLJS.M(null,"expressions","expressions",255689909);cP=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Tka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);dP=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Uka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);eP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);fP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);gP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Vka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
hP=new $CLJS.r(null,"get-day","get-day",1768100384,null);iP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);jP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);kP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.lP=new $CLJS.M(null,"native","native",-613060878);Wka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);mP=new $CLJS.M(null,"page","page",849072397);
nP=new $CLJS.r(null,"length","length",-2065447907,null);Xka=new $CLJS.M(null,"dashboard","dashboard",-631747508);oP=new $CLJS.r(null,"get-week","get-week",752472178,null);pP=new $CLJS.r(null,"get-month","get-month",1271156796,null);qP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);rP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);sP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
tP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);uP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);vP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Yka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);wP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);xP=new $CLJS.r(null,"substring","substring",-1513569493,null);
Zka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);yP=new $CLJS.M(null,"internal","internal",-854870097);$ka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);zP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);AP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
BP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);ala=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);CP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);DP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);bla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
cla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);EP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);FP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);GP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
dla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);ela=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);HP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.IP=new $CLJS.M(null,"template-tags","template-tags",1853115685);fla=new $CLJS.M(null,"public-question","public-question",629369976);gla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
hla=new $CLJS.M(null,"binary","binary",-1802232288);JP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);ila=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.KP=new $CLJS.M(null,"source-query","source-query",198004422);LP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);jla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
kla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);MP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);NP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);lla=new $CLJS.M(null,"executed-by","executed-by",-739811161);mla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
nla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);OP=new $CLJS.M(null,"amount","amount",364489504);PP=new $CLJS.r(null,"percentile","percentile",1039342775,null);QP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);RP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);SP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
TP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);UP=new $CLJS.r(null,"trim","trim",-1880116002,null);ola=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);VP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);WP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);pla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
XP=new $CLJS.M("string","\x3d","string/\x3d",983744235);qla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);rla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);YP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);ZP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
$P=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);tla=new $CLJS.M(null,"lat-min","lat-min",1630784161);aQ=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);bQ=new $CLJS.r(null,"inside","inside",-681932758,null);ula=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);cQ=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
vla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);dQ=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);wla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);xla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.eQ=new $CLJS.M(null,"card-id","card-id",-1770060179);fQ=new $CLJS.M(null,"variadic","variadic",882626057);
gQ=new $CLJS.r(null,"upper","upper",1886775433,null);hQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);iQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);jQ=new $CLJS.r(null,"optional","optional",-600484260,null);yla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);kQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
lQ=new $CLJS.M(null,"sugar","sugar",-73788488);zla=new $CLJS.M(null,"lat-max","lat-max",841568226);mQ=new $CLJS.r(null,"power","power",702679448,null);nQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);oQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);pQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
qQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);rQ=new $CLJS.r(null,"median","median",-2084869638,null);sQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);tQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Ala=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);uQ=new $CLJS.M(null,"y","y",-1757859776);vQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.wQ=new $CLJS.M(null,"binning","binning",1709835866);xQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);yQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);zQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);AQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);BQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);CQ=new $CLJS.M(null,"b","b",1482224470);
DQ=new $CLJS.M(null,"a","a",-2123407586);EQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);FQ=new $CLJS.r(null,"replace","replace",853943757,null);GQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Bla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);HQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
IQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);JQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);KQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);LQ=new $CLJS.r(null,"segment","segment",675610331,null);Cla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);MQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
NQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);OQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);PQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Dla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.QQ=new $CLJS.M(null,"order-by","order-by",1527318070);RQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ela=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Fla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.SQ=new $CLJS.M(null,"condition","condition",1668437652);TQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Gla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);UQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Hla=new $CLJS.M(null,"card-name","card-name",-2035606807);VQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Ila=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Jla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);WQ=new $CLJS.r(null,"log","log",45015523,null);Kla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
XQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.YQ=new $CLJS.M(null,"database_type","database_type",-54700895);ZQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);$Q=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.aR=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.bR=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.cR=new $CLJS.M("date","all-options","date/all-options",549325958);
Lla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Mla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);dR=new $CLJS.M("location","state","location/state",-114378652);eR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Nla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Ola=new $CLJS.M(null,"lon-field","lon-field",517872067);
fR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);gR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);hR=new $CLJS.M(null,"numeric","numeric",-1495594714);iR=new $CLJS.r(null,"variable","variable",1359185035,null);jR=new $CLJS.r(null,"lower","lower",-1534114948,null);Pla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
kR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);lR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.mR=new $CLJS.M(null,"limit","limit",-1355822363);nR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);oR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Qla=new $CLJS.M(null,"pulse","pulse",-244494476);var pR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tD],null),qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DD],null),rR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pD],null),sR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kD],null),tR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xD],null),uR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CD],null),Rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WJ],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PJ],null),vR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.KH],null),wR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OH],null),xR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FH],null),yR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.bi,null,$CLJS.Rj,null,$CLJS.Wi,null,$CLJS.Oh,null,$CLJS.qi,null,$CLJS.bj,null,$CLJS.Gj,null,$CLJS.kj,null,$CLJS.Wh,null,$CLJS.ij,null,$CLJS.ci,null,$CLJS.li,null],null),null),zR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Nj,null,$CLJS.WF,null,$CLJS.Oh,null,$CLJS.gi,null,$CLJS.ZF,null,$CLJS.Si,null,$CLJS.Di,null],null),null),
AR=$CLJS.Ws.g(yR,zR),Tla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"date bucketing unit"],null)],null),yR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"time bucketing unit"],null)],null),zR),BR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"datetime bucketing unit"],null)],null),AR),CR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jI],null),Vla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,
1,[$CLJS.ts,"temporal extract unit"],null),$CLJS.$F,$CLJS.li,$CLJS.Gj,$CLJS.$H,$CLJS.nI,$CLJS.oI,$CLJS.kj,$CLJS.qi,$CLJS.gi,$CLJS.Si,$CLJS.XF],null),Wla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"datetime-diff unit"],null),$CLJS.WF,$CLJS.Di,$CLJS.Nj,$CLJS.Rj,$CLJS.Wi,$CLJS.bj,$CLJS.bi,$CLJS.Wh],null),DR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"temporal-extract week extraction mode"],null),$CLJS.UH,$CLJS.aI,$CLJS.eI],null),ER=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Di,$CLJS.Nj,$CLJS.Rj,$CLJS.Wi,$CLJS.bj,$CLJS.bi,$CLJS.Wh],null),LR,Yla,TR,UR,VR,WR,YR,ZR,$R,MT,$la,NT,ama,bma,OT,cma,dma,ema;$CLJS.Y(WL,fL($CLJS.$A,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.Qu],null),$CLJS.pr],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,ER],null)])));var FR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WL],null);
$CLJS.Y(AO,fL($CLJS.ZA,$CLJS.H(["n",$CLJS.pr,"unit",ER])));var Xla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);
$CLJS.Y(qM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid :absolute-datetime clause",$CLJS.yi,function(a){if($CLJS.Ra(eL($CLJS.YH,a)))a=pM;else{a=$CLJS.dd(a);var b=$CLJS.aL(vR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.EO:$CLJS.bI}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[pM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EO,fL($CLJS.YH,
$CLJS.H(["date",vR,"unit",Tla]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bI,fL($CLJS.YH,$CLJS.H(["datetime",wR,"unit",BR]))],null)],null));var GR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);$CLJS.Y(FM,fL($CLJS.Fx,$CLJS.H(["time",xR,"unit",Ula])));var HR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FM],null),IR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"date or datetime literal"],null),GR,wR,vR],null);
$CLJS.Y(qL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"time literal"],null),HR,xR],null));$CLJS.Y(fR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"temporal literal"],null),IR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qL],null)],null));var JR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fR],null);
$CLJS.Y(pla,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof GR?new $CLJS.Cc(function(){return GR},$CLJS.kd(Qja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,yP,$CLJS.Vi,$CLJS.Wj,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gP,"metabase/mbql/schema.cljc",69,$CLJS.YH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:gP}(),GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof FR?new $CLJS.Cc(function(){return FR},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,
$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",29,$CLJS.$A,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.Oj,$CLJS.V($CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.Qu],null),$CLJS.pr],null)),$CLJS.di,$CLJS.V($CLJS.sj,$CLJS.V(jQ,WN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(FR)?FR.H:null])):null));return $CLJS.n(a)?a:yO}(),FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof HR?new $CLJS.Cc(function(){return HR},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,yP,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.V($CLJS.sj,ola),$CLJS.di,$CLJS.V($CLJS.sj,hka)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(HR)?HR.H:null])):null));return $CLJS.n(a)?a:XQ}(),HR],null)])));
$CLJS.Y(VQ,fL($CLJS.gj,$CLJS.H(["value",$CLJS.ur,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cC,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.er,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,BR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,pR],null)],null)],null)],null)])));var KR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.Y(FN,fL($CLJS.qA,$CLJS.H(["expression-name",pR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.Dj],null)])));
LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FN],null);
Yla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.FD);c=$CLJS.J.g(c,$CLJS.mD);return $CLJS.E.g(b,$CLJS.mD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.FD);c=$CLJS.J.g(c,$CLJS.ED);return $CLJS.E.g(b,$CLJS.ED)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"binning strategy"],null),$CLJS.mD,$CLJS.ED,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),sR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.ED,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.cl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.xl)],null)],null)],null)],null)));$CLJS.MR=function MR(a){switch(arguments.length){case 1:return MR.h(arguments[0]);case 2:return MR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.MR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tA);b=$CLJS.J.g(b,$CLJS.tF);return $CLJS.MR.g(a,b)};$CLJS.MR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.Kk)?yR:$CLJS.Dz(a,$CLJS.Qk)?zR:$CLJS.Dz(a,$CLJS.Nk)?AR:null:null;return $CLJS.n(a)?$CLJS.Cd(a,b):!0};$CLJS.MR.A=2;
$CLJS.Y(kQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,uR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,BR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,Yla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.MR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.FD)],null)],null));
$CLJS.Y(MQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(fL($CLJS.nF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,uR,pR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null)],null)]))));var NR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null);
$CLJS.Y(NO,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof LR?new $CLJS.Cc(function(){return LR},$CLJS.kd(RM,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.bR,$CLJS.V($CLJS.sj,YL),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,$CLJS.Dj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(LR)?LR.H:null])):null));return $CLJS.n(a)?a:BM}(),LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof NR?new $CLJS.Cc(function(){return NR},
$CLJS.kd(iN,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.gq,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],["0.39.0",$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jM,"metabase/mbql/schema.cljc",51,$CLJS.nF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(NR)?NR.H:null])):null));return $CLJS.n(a)?a:jM}(),NR],null)])));
$CLJS.OR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NO],null);$CLJS.Y(lL,fL($CLJS.cF,$CLJS.H(["aggregation-clause-index",$CLJS.pr,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.Dj],null)])));var PR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lL],null);
$CLJS.Y(SL,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof PR?new $CLJS.Cc(function(){return PR},$CLJS.kd(mla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vQ,"metabase/mbql/schema.cljc",23,$CLJS.cF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cF,Nla,$CLJS.V($CLJS.sj,$CLJS.pr),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,$CLJS.Dj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(PR)?PR.H:null])):null));return $CLJS.n(a)?a:vQ}(),PR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof LR?new $CLJS.Cc(function(){return LR},
$CLJS.kd(RM,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.bR,$CLJS.V($CLJS.sj,YL),
$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,$CLJS.Dj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(LR)?LR.H:null])):null));return $CLJS.n(a)?a:BM}(),LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof NR?new $CLJS.Cc(function(){return NR},$CLJS.kd(iN,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.gq,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,
$CLJS.ei,$CLJS.Fj,$CLJS.Kj],["0.39.0",$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jM,"metabase/mbql/schema.cljc",51,$CLJS.nF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(NR)?NR.H:null])):null));return $CLJS.n(a)?a:jM}(),NR],null)])));
var QR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SL],null),RR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.js,null,$CLJS.EF,null,$CLJS.FE,null,$CLJS.KF,null,$CLJS.ME,null,$CLJS.qF,null,$CLJS.WE,null,$CLJS.ps,null,$CLJS.CF,null,$CLJS.pF,null,$CLJS.LF,null],null),null),SR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PL],null);
$CLJS.Y(sL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.yi,function(a){return"string"===typeof a?$CLJS.yj:$CLJS.n(eL(RR,a))?LL:$CLJS.n(eL($CLJS.gj,a))?$CLJS.gj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[LL,SR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.OR],null)],null));TR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sL],null);
UR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.IE,null,$CLJS.mI,null,$CLJS.Nq,null,$CLJS.cB,null,$CLJS.hI,null,$CLJS.aF,null,$CLJS.Zr,null,$CLJS.EE,null,$CLJS.kI,null,$CLJS.aB,null,$CLJS.qF,null,$CLJS.HF,null,$CLJS.iI,null,$CLJS.sF,null,$CLJS.KE,null,$CLJS.WH,null,$CLJS.Mq,null,$CLJS.NE,null,$CLJS.WE,null,$CLJS.QH,null,$CLJS.Pw,null,$CLJS.XH,null,$CLJS.fI,null,$CLJS.YA,null,$CLJS.JF,null,$CLJS.lF,null],null),null);
VR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Kq,null,$CLJS.Eq,null,$CLJS.fr,null,$CLJS.hr,null,$CLJS.Gq,null,$CLJS.DF,null,$CLJS.kr,null,$CLJS.Pj,null,$CLJS.Iq,null],null),null);WR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.ti,null,$CLJS.BF,null,$CLJS.wF,null,$CLJS.oF,null,$CLJS.GF,null,$CLJS.IF,null,$CLJS.SE,null,$CLJS.ZE,null,$CLJS.xL,null,$CLJS.TE,null,$CLJS.AF,null,$CLJS.uF,null,$CLJS.Ej,null,$CLJS.Rw,null,$CLJS.hF,null,$CLJS.iF,null,$CLJS.zF,null],null),null);
YR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.cI,null,$CLJS.SH,null,$CLJS.eB,null,$CLJS.Mq,null,$CLJS.bB,null],null),null);ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null);$R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);$CLJS.aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EP],null);$CLJS.bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null);
$CLJS.Y(pQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"numeric expression argument",$CLJS.yi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.n(eL(UR,a))?gO:$CLJS.n(eL(WR,a))?$CLJS.cF:$CLJS.n(eL($CLJS.gj,a))?$CLJS.gj:$CLJS.nF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.cl],null),new $CLJS.P(null,2,5,$CLJS.Q,[gO,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,$CLJS.bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nF,
$CLJS.OR],null)],null));var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pQ],null);
$CLJS.Y(zP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"datetime expression argument",$CLJS.yi,function(a){return $CLJS.n(eL(WR,a))?$CLJS.cF:$CLJS.n(eL($CLJS.gj,a))?$CLJS.gj:$CLJS.n(eL(YR,a))?KM:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,$CLJS.bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[KM,$CLJS.aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,IR,$CLJS.OR],null)],null)],null));
var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zP],null);
$CLJS.Y(cP,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"expression argument",$CLJS.yi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.xd(a)?$CLJS.tr:$CLJS.n(eL(VR,a))?rP:$CLJS.n(eL(UR,a))?gO:$CLJS.n(eL(YR,a))?KM:"string"===typeof a?$CLJS.yj:$CLJS.n(eL(RR,a))?LL:$CLJS.n(eL($CLJS.gj,a))?$CLJS.gj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.cl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[rP,$R],null),new $CLJS.P(null,
2,5,$CLJS.Q,[gO,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[KM,$CLJS.aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[LL,SR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.OR],null)],null));var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);$CLJS.Y(aP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"numeric expression arg or interval"],null),Xla,cS],null));
var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aP],null);$CLJS.Y(DL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"int greater than zero or numeric expression",$CLJS.yi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,sR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,ZR],null)],null));var Zla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DL],null);
$CLJS.Y(LM,fL($CLJS.qF,$CLJS.H(["a",eS,"b",eS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,eS],null)])));var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.Y(pL,fL($CLJS.FE,$CLJS.H(["s",TR,"start",Zla,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,cS],null)])));var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pL],null);$CLJS.Y(sP,fL($CLJS.Pw,$CLJS.H(["s",TR])));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sP],null);$CLJS.Y(RL,fL($CLJS.CF,$CLJS.H(["s",TR])));
var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RL],null);$CLJS.Y(VL,fL($CLJS.LF,$CLJS.H(["s",TR])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VL],null);$CLJS.Y(nM,fL($CLJS.EF,$CLJS.H(["s",TR])));var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);$CLJS.Y(ZM,fL($CLJS.js,$CLJS.H(["s",TR])));var mS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);$CLJS.Y(IL,fL($CLJS.ps,$CLJS.H(["s",TR])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IL],null);
$CLJS.Y(oQ,fL($CLJS.KF,$CLJS.H(["s",TR,"match",$CLJS.yj,"replacement",$CLJS.yj])));var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);$CLJS.Y(kR,fL($CLJS.ME,$CLJS.H(["a",TR,"b",TR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,TR],null)])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kR],null);$CLJS.Y(ON,fL($CLJS.pF,$CLJS.H(["s",TR,"pattern",$CLJS.yj])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ON],null);
$CLJS.Y(KQ,fL($CLJS.Mq,$CLJS.H(["x",fS,"y",fS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,fS],null)])));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);$CLJS.Y(IQ,fL($CLJS.Zr,$CLJS.H(["x",cS,"y",fS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,fS],null)])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);$CLJS.Y(HQ,fL($CLJS.HF,$CLJS.H(["x",cS,"y",cS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,cS],null)])));var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);
$CLJS.Y(JQ,fL($CLJS.Nq,$CLJS.H(["x",cS,"y",cS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,cS],null)])));var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);$CLJS.Y($O,fL($CLJS.lF,$CLJS.H(["x",cS])));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$O],null);$CLJS.Y(SP,fL($CLJS.IE,$CLJS.H(["x",cS])));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SP],null);$CLJS.Y(nR,fL($CLJS.sF,$CLJS.H(["x",cS])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nR],null);$CLJS.Y(eO,fL($CLJS.NE,$CLJS.H(["x",cS])));
var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eO],null);$CLJS.Y(kO,fL($CLJS.KE,$CLJS.H(["x",cS,"y",cS])));var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kO],null);$CLJS.Y(HO,fL($CLJS.aF,$CLJS.H(["x",cS])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);$CLJS.Y(EL,fL($CLJS.EE,$CLJS.H(["x",cS])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EL],null);$CLJS.Y(fP,fL($CLJS.JF,$CLJS.H(["x",cS])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fP],null);
$CLJS.Y(dM,fL($CLJS.YA,$CLJS.H(["datetime-x",dS,"datetime-y",dS,"unit",Wla])));var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dM],null);$CLJS.Y(vM,fL($CLJS.cB,$CLJS.H(["datetime",dS,"unit",Vla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,DR],null)])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);$CLJS.Y(mO,fL($CLJS.XH,$CLJS.H(["date",dS])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);$CLJS.Y(OO,fL($CLJS.mI,$CLJS.H(["date",dS])));
var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OO],null);$CLJS.Y(rL,fL($CLJS.QH,$CLJS.H(["date",dS])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rL],null);$CLJS.Y(lR,fL($CLJS.aB,$CLJS.H(["date",dS,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,DR],null)])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);$CLJS.Y(zN,fL($CLJS.fI,$CLJS.H(["date",dS])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null);$CLJS.Y(IO,fL($CLJS.iI,$CLJS.H(["date",dS])));
var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IO],null);$CLJS.Y($L,fL($CLJS.kI,$CLJS.H(["datetime",dS])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$L],null);$CLJS.Y(EN,fL($CLJS.hI,$CLJS.H(["datetime",dS])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EN],null);$CLJS.Y(TL,fL($CLJS.WH,$CLJS.H(["datetime",dS])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TL],null);$CLJS.Y(jN,fL($CLJS.SH,$CLJS.H(["datetime",dS,"to",CR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,CR],null)])));
var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null),QS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"datetime arithmetic unit"],null),$CLJS.ZF,$CLJS.WF,$CLJS.Di,$CLJS.Nj,$CLJS.Rj,$CLJS.Wi,$CLJS.bj,$CLJS.bi,$CLJS.Wh],null);$CLJS.Y(oL,fL($CLJS.bB,$CLJS.H(["datetime",dS,"amount",cS,"unit",QS])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oL],null);
$CLJS.Y(ML,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return fL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.cI));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ML],null);$CLJS.Y(nL,fL($CLJS.eB,$CLJS.H(["datetime",dS,"amount",cS,"unit",QS])));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nL],null);
$CLJS.Y(EP,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},$CLJS.kd(UO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IN,"metabase/mbql/schema.cljc",51,$CLJS.Mq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Mq,$CLJS.BD,$CLJS.V($CLJS.sj,YO),uQ,$CLJS.V($CLJS.sj,YO),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,YO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:IN}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof RS?new $CLJS.Cc(function(){return RS},
$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[cN,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",67,$CLJS.bB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.bI,$CLJS.V($CLJS.sj,gR),OP,$CLJS.V($CLJS.sj,
TP),$CLJS.di,$CLJS.V($CLJS.sj,aQ)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:PO}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},$CLJS.kd(rja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,
$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[cN,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",72,$CLJS.eB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eB,$CLJS.bI,$CLJS.V($CLJS.sj,gR),OP,$CLJS.V($CLJS.sj,TP),$CLJS.di,$CLJS.V($CLJS.sj,aQ)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(TS)?TS.H:null])):null));
return $CLJS.n(a)?a:eR}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof PS?new $CLJS.Cc(function(){return PS},$CLJS.kd(vla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.SH,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JM,"metabase/mbql/schema.cljc",71,$CLJS.SH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.SH,$CLJS.bI,$CLJS.V($CLJS.sj,gR),qka,$CLJS.V($CLJS.sj,xQ),vja,$CLJS.V($CLJS.sj,$CLJS.V(jQ,xQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:JM}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&
"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},$CLJS.kd(Yka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cI,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",
45,$CLJS.cI,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cI],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:nO}(),SS],null)])));$CLJS.US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KO],null);$CLJS.Y(tL,fL($CLJS.kr,$CLJS.H(["first-clause",$CLJS.US,"second-clause",$CLJS.US,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,$CLJS.US],null)])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tL],null);
$CLJS.Y(fN,fL($CLJS.fr,$CLJS.H(["first-clause",$CLJS.US,"second-clause",$CLJS.US,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,$CLJS.US],null)])));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);$CLJS.Y(OL,fL($CLJS.hr,$CLJS.H(["clause",$CLJS.US])));
var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OL],null),YS=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,3,[$CLJS.ts,":field or :expression reference or :relative-datetime",$CLJS.Es,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.yi,function(a){return $CLJS.n(eL($CLJS.$A,a))?$CLJS.$A:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.OR],null)],null);
$CLJS.Y(BP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr,$CLJS.cl,$CLJS.yj,JR,YS,eS,KR],null)],null));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BP],null);
$CLJS.Y(TN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"order comparable",$CLJS.yi,function(a){return $CLJS.n(eL($CLJS.gj,a))?$CLJS.gj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fr,$CLJS.cl,$CLJS.yj,JR,eS,YS],null)],null)],null));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TN],null);
$CLJS.Y(AQ,fL($CLJS.Pj,$CLJS.H(["field",ZS,"value-or-field",ZS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,ZS],null)])));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);$CLJS.Y(EQ,fL($CLJS.DF,$CLJS.H(["field",ZS,"value-or-field",ZS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,ZS],null)])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);$CLJS.Y(zQ,fL($CLJS.Iq,$CLJS.H(["field",$S,"value-or-field",$S])));
var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);$CLJS.Y(yQ,fL($CLJS.Eq,$CLJS.H(["field",$S,"value-or-field",$S])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);$CLJS.Y(oM,fL($CLJS.Kq,$CLJS.H(["field",$S,"value-or-field",$S])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);$CLJS.Y(uM,fL($CLJS.Gq,$CLJS.H(["field",$S,"value-or-field",$S])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);$CLJS.Y(jP,fL($CLJS.PE,$CLJS.H(["field",$S,"min",$S,"max",$S])));
var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jP],null);$CLJS.Y(LP,fL($CLJS.VE,$CLJS.H(["lat-field",$S,"lon-field",$S,"lat-max",$S,"lon-min",$S,"lat-min",$S,"lon-max",$S])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.Y(YM,fL($CLJS.OE,$CLJS.H(["field",$CLJS.OR])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.Y(NN,fL($CLJS.$E,$CLJS.H(["field",$CLJS.OR])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null);$CLJS.Y(pN,fL($CLJS.QE,$CLJS.H(["field",$CLJS.OR])));
var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);$CLJS.Y(wL,fL($CLJS.XE,$CLJS.H(["field",$CLJS.OR])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wL],null),mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null);$CLJS.Y(CN,fL($CLJS.eF,$CLJS.H(["field",TR,"string-or-field",TR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,mT],null)])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CN],null);
$CLJS.Y(vO,fL($CLJS.yF,$CLJS.H(["field",TR,"string-or-field",TR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,mT],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null);$CLJS.Y(nN,fL($CLJS.mF,$CLJS.H(["field",TR,"string-or-field",TR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,mT],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);$CLJS.Y(dP,fL($CLJS.fF,$CLJS.H(["field",TR,"string-or-field",TR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,mT],null)])));
var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null);$CLJS.Y(FL,fL($CLJS.dB,$CLJS.H(["field",$CLJS.OR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,$CLJS.Qu,$CLJS.Vw,$CLJS.UE],null)],null),"unit",ER,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FL],null);
$CLJS.Y(uP,fL($CLJS.LE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lD],null),pR],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);
$CLJS.Y(DM,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},$CLJS.kd(nQ,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XN,"metabase/mbql/schema.cljc",15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,NM,$CLJS.V($CLJS.sj,bO),FO,$CLJS.V($CLJS.sj,bO),XM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,bO))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:XN}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},
$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",14,$CLJS.fr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fr,NM,$CLJS.V($CLJS.sj,bO),FO,$CLJS.V($CLJS.sj,bO),XM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,bO))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:MO}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.kd(vP,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",15,$CLJS.hr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.aR,$CLJS.V($CLJS.sj,bO)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:oO}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==
typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(SO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",13,$CLJS.Iq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Iq,$CLJS.nF,$CLJS.V($CLJS.sj,PM),ZP,$CLJS.V($CLJS.sj,PM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:QN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(UQ,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",14,$CLJS.Kq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kq,$CLJS.nF,$CLJS.V($CLJS.sj,PM),ZP,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:PN}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&
"undefined"!==typeof kL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(QO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",13,$CLJS.Eq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Eq,$CLJS.nF,$CLJS.V($CLJS.sj,
PM),ZP,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:RN}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.kd(NQ,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],
[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",14,$CLJS.Gq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gq,$CLJS.nF,$CLJS.V($CLJS.sj,PM),ZP,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:YN}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&
"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(RO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",13,$CLJS.Pj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Pj,$CLJS.nF,$CLJS.V($CLJS.sj,ZQ),ZP,$CLJS.V($CLJS.sj,ZQ),CP,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,ZQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:SN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(eM,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,
$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",14,$CLJS.DF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DF,$CLJS.nF,$CLJS.V($CLJS.sj,ZQ),ZP,$CLJS.V($CLJS.sj,ZQ),CP,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,ZQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(bT)?bT.H:null])):null));
return $CLJS.n(a)?a:AM}(),bT],null)])));
$CLJS.Y(KO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid filter expression",$CLJS.yi,function(a){return $CLJS.n(eL(YR,a))?$CLJS.bI:$CLJS.n(eL(UR,a))?hR:$CLJS.n(eL(RR,a))?$CLJS.yj:$CLJS.n(eL(VR,a))?$CLJS.tr:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bI,$CLJS.aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[hR,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,SR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,gL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},$CLJS.kd(nQ,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XN,"metabase/mbql/schema.cljc",
15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,NM,$CLJS.V($CLJS.sj,bO),FO,$CLJS.V($CLJS.sj,bO),XM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,bO))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:XN}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.zj,
!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",14,$CLJS.fr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fr,NM,$CLJS.V($CLJS.sj,bO),FO,$CLJS.V($CLJS.sj,bO),XM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,bO))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(WS)?
WS.H:null])):null));return $CLJS.n(a)?a:MO}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.kd(vP,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oO,"metabase/mbql/schema.cljc",15,$CLJS.hr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.aR,$CLJS.V($CLJS.sj,bO)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:oO}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(RO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,
$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",13,$CLJS.Pj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Pj,$CLJS.nF,$CLJS.V($CLJS.sj,ZQ),ZP,$CLJS.V($CLJS.sj,ZQ),CP,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,ZQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(aT)?aT.H:null])):
null));return $CLJS.n(a)?a:SN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(eM,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AM,"metabase/mbql/schema.cljc",14,$CLJS.DF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DF,$CLJS.nF,$CLJS.V($CLJS.sj,ZQ),ZP,$CLJS.V($CLJS.sj,ZQ),CP,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,ZQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:AM}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},
$CLJS.kd(SO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",13,$CLJS.Iq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Iq,$CLJS.nF,$CLJS.V($CLJS.sj,PM),ZP,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:QN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(QO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RN,"metabase/mbql/schema.cljc",13,$CLJS.Eq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Eq,$CLJS.nF,$CLJS.V($CLJS.sj,PM),ZP,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:RN}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(UQ,new $CLJS.h(null,
1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",14,$CLJS.Kq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kq,$CLJS.nF,$CLJS.V($CLJS.sj,PM),ZP,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(eT)?eT.H:null])):
null));return $CLJS.n(a)?a:PN}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.kd(NQ,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YN,"metabase/mbql/schema.cljc",14,$CLJS.Gq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gq,$CLJS.nF,$CLJS.V($CLJS.sj,PM),ZP,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:YN}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.kd(Qka,new $CLJS.h(null,
1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gM,"metabase/mbql/schema.cljc",19,$CLJS.PE,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PE,$CLJS.nF,$CLJS.V($CLJS.sj,PM),$CLJS.ti,$CLJS.V($CLJS.sj,PM),$CLJS.Ej,$CLJS.V($CLJS.sj,PM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:gM}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof nT?new $CLJS.Cc(function(){return nT},$CLJS.kd(Ska,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dQ,"metabase/mbql/schema.cljc",23,$CLJS.eF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eF,$CLJS.nF,$CLJS.V($CLJS.sj,YP),zM,$CLJS.V($CLJS.sj,YP),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,tP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:dQ}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},
$CLJS.kd(Tja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EM,"metabase/mbql/schema.cljc",21,$CLJS.yF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yF,$CLJS.nF,$CLJS.V($CLJS.sj,YP),zM,$CLJS.V($CLJS.sj,YP),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,tP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:EM}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},$CLJS.kd(fka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QM,"metabase/mbql/schema.cljc",20,$CLJS.mF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mF,$CLJS.nF,$CLJS.V($CLJS.sj,YP),zM,$CLJS.V($CLJS.sj,YP),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,tP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:QM}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==
typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.kd(Jla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uL,"metabase/mbql/schema.cljc",36,$CLJS.fF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.fF,$CLJS.nF,$CLJS.V($CLJS.sj,YP),zM,$CLJS.V($CLJS.sj,YP),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,tP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:uL}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof hT?new $CLJS.Cc(function(){return hT},$CLJS.kd(sla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),
$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bQ,"metabase/mbql/schema.cljc",26,$CLJS.VE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.VE,Bja,$CLJS.V($CLJS.sj,PM),Ola,$CLJS.V($CLJS.sj,PM),zla,$CLJS.V($CLJS.sj,PM),Eja,$CLJS.V($CLJS.sj,PM),tla,$CLJS.V($CLJS.sj,PM),Aja,$CLJS.V($CLJS.sj,
PM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:bQ}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof kT?new $CLJS.Cc(function(){return kT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",28,$CLJS.QE,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,$CLJS.nF,$CLJS.V($CLJS.sj,IM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:xO}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&
"undefined"!==typeof lT?new $CLJS.Cc(function(){return lT},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",29,$CLJS.XE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,$CLJS.nF,$CLJS.V($CLJS.sj,IM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:kP}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof iT?new $CLJS.Cc(function(){return iT},$CLJS.kd(Ela,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WM,"metabase/mbql/schema.cljc",27,$CLJS.OE,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,$CLJS.nF,$CLJS.V($CLJS.sj,IM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:WM}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&
"undefined"!==typeof jT?new $CLJS.Cc(function(){return jT},$CLJS.kd(ska,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lM,"metabase/mbql/schema.cljc",28,$CLJS.$E,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,$CLJS.nF,$CLJS.V($CLJS.sj,IM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:lM}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",33,$CLJS.dB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dB,$CLJS.nF,$CLJS.V($CLJS.sj,IM),$CLJS.Oj,$CLJS.V($CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hj,$CLJS.Qu,$CLJS.Vw,$CLJS.UE],null)],null)),$CLJS.di,$CLJS.V($CLJS.sj,WN),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,Vka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:JP}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LQ,"metabase/mbql/schema.cljc",27,$CLJS.LE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LE,Ija,$CLJS.V($CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,jla,YL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:LQ}(),sT],null)]))],null)],null));
$CLJS.Y(wP,fL($CLJS.WE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.h(null,1,[$CLJS.ts,":case subclause"],null),$CLJS.US,eS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),eS],null)],null)],null)])));
var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wP],null);
$CLJS.Y(cQ,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},$CLJS.kd(UO,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IN,"metabase/mbql/schema.cljc",51,$CLJS.Mq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Mq,$CLJS.BD,$CLJS.V($CLJS.sj,YO),uQ,$CLJS.V($CLJS.sj,YO),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,YO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:IN}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},
$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",51,$CLJS.Zr,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Zr,$CLJS.BD,$CLJS.V($CLJS.sj,TP),
uQ,$CLJS.V($CLJS.sj,YO),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,YO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:JN}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL?new $CLJS.Cc(function(){return uS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,lO,$CLJS.Ci,$CLJS.Vi,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.HF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HF,$CLJS.BD,$CLJS.V($CLJS.sj,TP),uQ,$CLJS.V($CLJS.sj,TP),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,TP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof vS?new $CLJS.Cc(function(){return vS},$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HN,"metabase/mbql/schema.cljc",51,$CLJS.Nq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Nq,$CLJS.BD,$CLJS.V($CLJS.sj,TP),uQ,$CLJS.V($CLJS.sj,TP),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,TP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?a:HN}(),vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},
$CLJS.kd(GP,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",58,$CLJS.qF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qF,DQ,$CLJS.V($CLJS.sj,zL),CQ,$CLJS.V($CLJS.sj,
zL),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,zL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:VP}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,
$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.V($CLJS.sj,YP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:nP}(),iS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iO,"metabase/mbql/schema.cljc",55,$CLJS.lF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,$CLJS.BD,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:iO}(),wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),
$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XL,"metabase/mbql/schema.cljc",54,$CLJS.IE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IE,$CLJS.BD,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(xS)?
xS.H:null])):null));return $CLJS.n(a)?a:XL}(),xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZN,"metabase/mbql/schema.cljc",55,$CLJS.sF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,$CLJS.BD,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:ZN}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&
"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",53,$CLJS.NE,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.NE,$CLJS.BD,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:BO}(),zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.kd(xla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,
hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[RQ,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",69,$CLJS.KE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.KE,$CLJS.BD,$CLJS.V($CLJS.sj,TP),uQ,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:mQ}(),AS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.kd(vka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[RQ,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",68,$CLJS.aF,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,$CLJS.BD,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:DN}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.kd(yla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),
$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[RQ,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aO,"metabase/mbql/schema.cljc",67,$CLJS.EE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,$CLJS.BD,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(CS)?
CS.H:null])):null));return $CLJS.n(a)?a:aO}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.kd(Hja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[RQ,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",67,$CLJS.JF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.BD,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:WQ}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&
"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.kd(KL,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",61,$CLJS.WE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.WE,$CLJS.bw,$CLJS.V($CLJS.sj,bN),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,LO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:sO}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.kd(Kja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,
$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YA,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CO,"metabase/mbql/schema.cljc",65,$CLJS.YA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YA,Xja,$CLJS.V($CLJS.sj,gR),Yja,$CLJS.V($CLJS.sj,gR),$CLJS.di,$CLJS.V($CLJS.sj,Dka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:CO}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.kd(gla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,
new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",71,$CLJS.cB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cB,$CLJS.bI,$CLJS.V($CLJS.sj,gR),$CLJS.di,$CLJS.V($CLJS.sj,Dla),$CLJS.ji,$CLJS.V($CLJS.sj,$CLJS.V(jQ,oR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:VO}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.kd(oka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tM,"metabase/mbql/schema.cljc",71,$CLJS.XH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XH,$CLJS.EO,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:tM}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.zj,!0],
null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",74,$CLJS.mI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mI,$CLJS.EO,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:TO}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",72,$CLJS.QH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QH,$CLJS.EO,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:pP}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==
typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof JS?new $CLJS.Cc(function(){return JS},$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",
71,$CLJS.aB,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aB,$CLJS.EO,$CLJS.V($CLJS.sj,gR),$CLJS.ji,$CLJS.V($CLJS.sj,$CLJS.V(jQ,oR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:oP}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.zj,
!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",70,$CLJS.fI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fI,$CLJS.EO,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:hP}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof LS?new $CLJS.Cc(function(){return LS},$CLJS.kd(Eka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",78,$CLJS.iI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iI,$CLJS.EO,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:ZO}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==
typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof MS?new $CLJS.Cc(function(){return MS},$CLJS.kd(Cka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AL,"metabase/mbql/schema.cljc",
71,$CLJS.kI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kI,$CLJS.bI,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:AL}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof NS?new $CLJS.Cc(function(){return NS},$CLJS.kd(kla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,
$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",73,$CLJS.hI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hI,$CLJS.bI,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(NS)?NS.H:null])):null));
return $CLJS.n(a)?a:$Q}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,
[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",73,$CLJS.WH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WH,$CLJS.bI,$CLJS.V($CLJS.sj,gR)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:uO}(),OS],null)])));
$CLJS.Y(PL,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},$CLJS.kd(ala,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xP,"metabase/mbql/schema.cljc",59,$CLJS.FE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FE,$CLJS.lx,$CLJS.V($CLJS.sj,YP),$CLJS.vu,$CLJS.V($CLJS.sj,$ja),$CLJS.Pw,$CLJS.V($CLJS.sj,$CLJS.V(jQ,TP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:xP}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},
$CLJS.kd(Ala,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",54,$CLJS.CF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,$CLJS.lx,$CLJS.V($CLJS.sj,YP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:UP}(),jS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof lS?new $CLJS.Cc(function(){return lS},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",55,$CLJS.EF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,$CLJS.lx,$CLJS.V($CLJS.sj,YP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:DP}(),lS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&
"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GO,"metabase/mbql/schema.cljc",
55,$CLJS.LF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.lx,$CLJS.V($CLJS.sj,YP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:GO}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof pS?new $CLJS.Cc(function(){return pS},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,
$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FQ,"metabase/mbql/schema.cljc",57,$CLJS.KF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KF,$CLJS.lx,$CLJS.V($CLJS.sj,YP),Fja,$CLJS.V($CLJS.sj,$CLJS.yj),$CLJS.iz,$CLJS.V($CLJS.sj,$CLJS.yj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:FQ}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof oS?new $CLJS.Cc(function(){return oS},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,
new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",55,$CLJS.ps,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.lx,$CLJS.V($CLJS.sj,YP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:jR}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==
typeof kL&&"undefined"!==typeof mS?new $CLJS.Cc(function(){return mS},$CLJS.kd(Bla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",55,$CLJS.js,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.lx,$CLJS.V($CLJS.sj,YP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:gQ}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},$CLJS.kd(Uka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,
$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uN,"metabase/mbql/schema.cljc",56,$CLJS.ME,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ME,DQ,$CLJS.V($CLJS.sj,YP),CQ,$CLJS.V($CLJS.sj,YP),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,YP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:uN}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},$CLJS.kd($ka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.bP,"null",$CLJS.mj,"null"],
null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rM,"metabase/mbql/schema.cljc",74,$CLJS.pF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pF,$CLJS.lx,$CLJS.V($CLJS.sj,YP),$CLJS.hz,$CLJS.V($CLJS.sj,$CLJS.yj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:rM}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==
typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},$CLJS.kd(GP,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bP,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
VP,"metabase/mbql/schema.cljc",58,$CLJS.qF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qF,DQ,$CLJS.V($CLJS.sj,zL),CQ,$CLJS.V($CLJS.sj,zL),MM,$CLJS.V($CLJS.sj,$CLJS.V($CLJS.wi,zL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:VP}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},
$CLJS.kd(KL,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",61,$CLJS.WE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WE,$CLJS.bw,$CLJS.V($CLJS.sj,bN),
$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,LO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:sO}(),tT],null)])));
$CLJS.uT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,":field or :expression reference or expression",$CLJS.yi,function(a){return $CLJS.n(eL(UR,a))?hR:$CLJS.n(eL(RR,a))?$CLJS.yj:$CLJS.n(eL(VR,a))?$CLJS.tr:$CLJS.n(eL(YR,a))?$CLJS.bI:$CLJS.n(eL($CLJS.WE,a))?$CLJS.WE:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[hR,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,SR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bI,$CLJS.aS],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.OR],null)],null);$CLJS.Y(OQ,fL($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.OR],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.Y(DO,fL($CLJS.oF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.OR],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DO],null);$CLJS.Y(NL,fL($CLJS.hF,$CLJS.H(["field-or-expression",$CLJS.uT])));
var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NL],null);$CLJS.Y(GQ,fL($CLJS.IF,$CLJS.H(["field-or-expression",$CLJS.uT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);$CLJS.Y(mN,fL($CLJS.SE,$CLJS.H(["field-or-expression",$CLJS.uT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.Y(WP,fL($CLJS.zF,$CLJS.H(["field-or-expression",$CLJS.uT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WP],null);$CLJS.Y(hN,fL($CLJS.ti,$CLJS.H(["field-or-expression",$CLJS.uT])));
var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null);$CLJS.Y(UM,fL($CLJS.Ej,$CLJS.H(["field-or-expression",$CLJS.uT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);$CLJS.Y(OM,fL($CLJS.GF,$CLJS.H(["field-or-expression",$CLJS.uT,"pred",$CLJS.US])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);$CLJS.Y(eN,fL($CLJS.wF,$CLJS.H(["pred",$CLJS.US])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null);$CLJS.Y(NP,fL($CLJS.uF,$CLJS.H(["pred",$CLJS.US])));
var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.Y(kM,fL($CLJS.BF,$CLJS.H(["field-or-expression",$CLJS.uT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kM],null);$CLJS.Y(mL,fL($CLJS.TE,$CLJS.H(["field-or-expression",$CLJS.uT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mL],null);$CLJS.Y(qN,fL($CLJS.AF,$CLJS.H(["field-or-expression",$CLJS.uT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);$CLJS.Y(dO,fL($CLJS.ZE,$CLJS.H(["field-or-expression",$CLJS.uT,"percentile",cS])));
var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.Y(QP,fL($CLJS.iF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jD],null),pR],null)])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);
$CLJS.Y(MP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"unnamed aggregation clause or numeric expression",$CLJS.yi,function(a){return $CLJS.n(eL(UR,a))?gO:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[gO,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.kd(Zka,
new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",60,$CLJS.hF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:pO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,
new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",64,$CLJS.IF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:tQ}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==
typeof kL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",65,$CLJS.SE,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:tO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof GT?new $CLJS.Cc(function(){return GT},$CLJS.kd(ula,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,
$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.xF,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HL,"metabase/mbql/schema.cljc",76,$CLJS.BF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:HL}(),GT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cM,"metabase/mbql/schema.cljc",60,$CLJS.zF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:cM}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.kd(Cja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,
$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Mr,"metabase/mbql/schema.cljc",60,$CLJS.ti,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ti,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(BT)?BT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Mr}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.kd(xja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Qr,"metabase/mbql/schema.cljc",60,$CLJS.Ej,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Qr}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&
"undefined"!==typeof KT?new $CLJS.Cc(function(){return KT},$CLJS.kd(Zja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tN,"metabase/mbql/schema.cljc",18,$CLJS.iF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,lka,$CLJS.V($CLJS.sj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.fr,pka,YL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:tN}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.kd(xka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,
$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",62,$CLJS.uF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,$CLJS.zn,$CLJS.V($CLJS.sj,bO)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:lN}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZL,"metabase/mbql/schema.cljc",68,$CLJS.wF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wF,$CLJS.zn,$CLJS.V($CLJS.sj,bO)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:ZL}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.kd(Ila,new $CLJS.h(null,1,[$CLJS.zj,!0],
null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",66,$CLJS.GF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.GF,hQ,$CLJS.V($CLJS.sj,$P),$CLJS.zn,$CLJS.V($CLJS.sj,bO)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:qO}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.kd(KL,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,
new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",61,$CLJS.WE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WE,$CLJS.bw,$CLJS.V($CLJS.sj,bN),$CLJS.Qi,$CLJS.V($CLJS.sj,$CLJS.V(jQ,LO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:sO}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&
"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof IT?new $CLJS.Cc(function(){return IT},$CLJS.kd(nla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.jF,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",
68,$CLJS.AF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:rQ}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof JT?new $CLJS.Cc(function(){return JT},$CLJS.kd(Jka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,
lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.jF,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PP,"metabase/mbql/schema.cljc",72,$CLJS.ZE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZE,hQ,$CLJS.V($CLJS.sj,$P),$CLJS.ZE,$CLJS.V($CLJS.sj,TP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(JT)?JT.H:null])):
null));return $CLJS.n(a)?a:PP}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof HT?new $CLJS.Cc(function(){return HT},$CLJS.kd(Nja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CL,"metabase/mbql/schema.cljc",77,$CLJS.TE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,hQ,$CLJS.V($CLJS.sj,$P)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:CL}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,
$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cO,"metabase/mbql/schema.cljc",74,$CLJS.oF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,$CLJS.nF,$CLJS.V($CLJS.sj,$CLJS.V(jQ,IM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:cO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof vT?new $CLJS.Cc(function(){return vT},$CLJS.kd(aka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([jO,$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,lQ,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.HE,"null"],null),null),
$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.hi,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.nF,$CLJS.V($CLJS.sj,$CLJS.V(jQ,IM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:$CLJS.hi}(),vT],null)]))],null)],null));
$CLJS.Y(eP,fL($CLJS.xL,$CLJS.H(["aggregation",MP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null)],null)])));
$CLJS.Y(iQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"aggregation clause or numeric expression",$CLJS.yi,function(a){return $CLJS.n(eL($CLJS.xL,a))?$CLJS.xL:aN}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[aN,MP],null)],null));$CLJS.Y(GL,fL($CLJS.kF,$CLJS.H(["field",QR])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GL],null);$CLJS.Y(fO,fL($CLJS.dF,$CLJS.H(["field",QR])));
MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null);
$la=gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof LT?new $CLJS.Cc(function(){return LT},$CLJS.kd(rla,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
MN,"metabase/mbql/schema.cljc",15,$CLJS.kF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,$CLJS.nF,$CLJS.V($CLJS.sj,BQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:MN}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof MT?new $CLJS.Cc(function(){return MT},$CLJS.kd(jka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,
$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HP,"metabase/mbql/schema.cljc",16,$CLJS.dF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF,$CLJS.nF,$CLJS.V($CLJS.sj,BQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:HP}(),MT],null)]));
NT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Hj,$CLJS.JL,$CLJS.yM,$CLJS.GE,$CLJS.Ps,$CLJS.Mj,$CLJS.EO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,pR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oD,pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null)],null);
ama=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,NT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.JL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vL,pR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WO,sR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),sR],null)],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,NT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.yM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eQ,sR],null)],null)],null);
OT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,NT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null);cma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sQ],null);
dma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,OT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.GE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,$CLJS.Ii,$CLJS.ur],
null)],null)],null)],null)],null);$CLJS.PT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.EO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.Mj,null],null),null);ema=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hj],null),$CLJS.PT);
$CLJS.Y(AP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.yi,$CLJS.Ui],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,dma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JL,ama],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yM,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$n,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,OT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,ema],null)],null)],null)],null)],null));
var QT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,pR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.U.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,pR],null)],null)],null),fma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,QT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.ur],null)],null)],null),RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iP],null),ST=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.yi,function(a){return $CLJS.n($CLJS.fD($CLJS.sd,
$CLJS.lP)(a))?$CLJS.lP:$CLJS.nD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,QT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lP,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nD,RT],null)],null),TT=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,pR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cC,qR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rka,pR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.GN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Dj],null)],null)],null),UT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,Sla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),gma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hj],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.kE,null,$CLJS.UD,null,$CLJS.dE,null,$CLJS.hE,null],null),null)),VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qQ],null);
$CLJS.Y(kN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),UT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SQ,$CLJS.US],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,$CLJS.wx,$CLJS.cz],null),VT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,uR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,TT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.fD($CLJS.zz($CLJS.hO,$CLJS.KP),$CLJS.Oe($CLJS.fD($CLJS.hO,$CLJS.KP)))],null)],null));var hma=$CLJS.Q,WT;var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null)],null);
if($CLJS.rd(XT)&&$CLJS.E.g($CLJS.z(XT),$CLJS.dj)){var YT=$CLJS.y(XT);$CLJS.z(YT);var ZT=$CLJS.B(YT),ima=$CLJS.sd($CLJS.z(ZT))?ZT:$CLJS.ae(null,ZT),$T=$CLJS.y(ima),jma=$CLJS.z($T),kma=$CLJS.B($T);WT=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.R.j(jma,$CLJS.ti,1)],null),kma)}else WT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,XT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(mM,new $CLJS.P(null,3,5,hma,[$CLJS.kr,WT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"All join aliases must be unique."],null),function(a){return $CLJS.RK($CLJS.ff($CLJS.Sa,$CLJS.cf.g($CLJS.ZD,a)))}],null)],null));var lma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null);
$CLJS.Y(qQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.ts,"Distinct, non-empty sequence of Field clauses"],null),hL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.ti,1],null),$CLJS.OR],null))],null));
$CLJS.Y(iP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),ST],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),UT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.ti,1],null),$CLJS.bS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF,new $CLJS.h(null,1,
[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.ti,1],null),$CLJS.OR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,pR,$CLJS.uT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),VT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.US],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mR,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),tR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),hL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.ti,1],null),$la],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[mP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[mP,sR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sja,sR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,TT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Gl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KP,$CLJS.hO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,
new $CLJS.h(null,1,[$CLJS.ts,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.bF);b=$CLJS.J.g(b,$CLJS.VD);return $CLJS.od($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.aU=$CLJS.zg([$CLJS.FF,$CLJS.EO,gN,SM,VN,dR,BN,$CLJS.cR,$CLJS.Ps,AN,QL,XP,TQ,$CLJS.fM,PQ,dN,$CLJS.$N,yN,$CLJS.Ki,$CLJS.xN,yL,$CLJS.CM,$CLJS.wN,zO,$CLJS.tr,$CLJS.Mj,sN],[new $CLJS.h(null,1,[LN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.FF,null,$CLJS.EO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.Mj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.EO,LN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.FF,null,$CLJS.EO,null,$CLJS.cR,null,$CLJS.$N,null,$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ui,$CLJS.yj,$CLJS.wD,fQ,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[gN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,hR,$CLJS.wD,fQ,LN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.FF,null,SM,null,$CLJS.Ps,null,$CLJS.Ki,null,yL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,$CLJS.yj,$CLJS.wD,UL,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[VN,null],null),null)],null),new $CLJS.h(null,1,[LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[dR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,
hR,$CLJS.wD,hla,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[BN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.EO,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,hR,LN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.FF,null,SM,null,$CLJS.Ps,null,$CLJS.Ki,null,yL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,hR,$CLJS.wD,UL,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[AN,null],null),null)],null),new $CLJS.h(null,1,[LN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[QL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,$CLJS.yj,$CLJS.wD,fQ,LN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.FF,null,dR,null,QL,null,XP,null,yN,null,$CLJS.Ki,null,yL,null,$CLJS.Mj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,$CLJS.yj,$CLJS.wD,UL,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[TQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.EO,LN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.cR,null,$CLJS.fM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ui,$CLJS.yj,$CLJS.wD,UL,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[PQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,$CLJS.yj,$CLJS.wD,UL,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[dN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.EO,LN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.FF,null,$CLJS.EO,null,$CLJS.cR,null,$CLJS.$N,null,$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,1,[LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[yN,null],null),null)],null),new $CLJS.h(null,1,[LN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.EO,LN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.cR,null,$CLJS.xN,null],null),null)],null),new $CLJS.h(null,1,[LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[yL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.EO,LN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.cR,null,$CLJS.CM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.EO,LN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.cR,
null,$CLJS.wN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,hR,$CLJS.wD,fQ,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[zO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.tr,LN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.FF,null,$CLJS.Ki,null,$CLJS.tr,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.yj,LN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.FF,null,dR,null,QL,null,XP,null,yN,null,$CLJS.Ki,null,yL,null,$CLJS.Mj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ui,
hR,$CLJS.wD,UL,LN,new $CLJS.Rg(null,new $CLJS.h(null,1,[sN,null],null),null)],null)]);$CLJS.Y(sQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.aU)));$CLJS.Y(bM,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.aU)));
$CLJS.Y(FP,fL(Jja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,pR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,pR],null)],null)],null)])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FP],null);$CLJS.Y(BL,fL($CLJS.GE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.OR,bU],null)])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BL],null);$CLJS.Y(wM,fL(vN,$CLJS.H(["target",bU])));
var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null),mma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.OR,gL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof cU?new $CLJS.Cc(function(){return cU},$CLJS.kd(Hka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,
1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",21,$CLJS.GE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GE,$CLJS.pI,$CLJS.V($CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,IM,KN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:qP}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=lO.h($CLJS.ld("undefined"!==typeof $CLJS.iL&&
"undefined"!==typeof jL&&"undefined"!==typeof kL&&"undefined"!==typeof dU?new $CLJS.Cc(function(){return dU},$CLJS.kd(Wka,new $CLJS.h(null,1,[$CLJS.zj,!0],null)),$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,lO,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,hM,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[$CLJS.kd(RP,new $CLJS.h(null,1,[$CLJS.Fj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iR,"metabase/mbql/schema.cljc",20,vN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[vN,
$CLJS.pI,$CLJS.V($CLJS.sj,KN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:iR}(),dU],null)]))],null),bL=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iM],null);
$CLJS.Y(iM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.fD($CLJS.zz($CLJS.lP,$CLJS.JE),$CLJS.Oe($CLJS.fD($CLJS.lP,$CLJS.JE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.lP);var d=$CLJS.J.g(c,$CLJS.JE);c=$CLJS.J.g(c,$CLJS.Ui);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.XJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,$CLJS.JE,$CLJS.lP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),fma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),RT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,cma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pI,new $CLJS.h(null,1,[$CLJS.yr,!0],null),mma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),CR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Gka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[rO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),tR],null),new $CLJS.P(null,3,5,$CLJS.Q,[aM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),tR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,rO);b=$CLJS.J.g(b,aM);return $CLJS.Ra($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[qla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tr],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[uja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tr],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.Hj,$CLJS.hj,Tka,$CLJS.oN,Vja,Qla,Xka,mka,Ika,eka,Mja,ika,fla,uka,Gja,Wja,Pla,Mla],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[lla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,Rla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,pR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[tka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,$CLJS.ur,$CLJS.ur],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,$CLJS.ur,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.ur],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.Oe($CLJS.UN)],null)],null));var eU=$CLJS.aL(bL);(function(){var a=pja();return function(b){if($CLJS.n(eU.h?eU.h(b):eU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.YK(b);throw $CLJS.Uh($CLJS.hD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Bka,b],null));}})();