var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var XV,ZV,aW,bW,Xma,dW,eW,WV,fW,iW,Yma,Zma,$ma,ana,kW,lW,nW,oW,pW,qW,rW,sW,cna,dna,uW,ena,vW,fna,xW,gna,hna,ina,jna,kna,TV;$CLJS.UV=function(a,b){if("string"===typeof b)return TV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.VV=function(a,b,c){var d=$CLJS.Hl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Cb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
XV=function(a){var b=WV;return $CLJS.Iz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.YV=function(a,b){return $CLJS.Iz(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};ZV=function(a){if($CLJS.id(a))return a;throw $CLJS.Uh("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.ri,a],null));};
$CLJS.$V=function(a,b,c){return $CLJS.sd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,X=G;G=$CLJS.Yd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[X,G],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.Yd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):$CLJS.rd(c)?$CLJS.ok.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),c):c};aW=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.de(a):b;return $CLJS.n(b)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
bW=function(a,b){var c=$CLJS.rd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Xma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.ud(c)&&1<=$CLJS.D(c))try{var e=$CLJS.tk.j(c,0,1);if($CLJS.ud(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.tk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Sa,l)))},$CLJS.H([b]))};
dW=function(a,b){for(;;)switch(b=$CLJS.Gk.h($CLJS.ff($CLJS.Sa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),cW.h?cW.h(a):cW.call(null,a);default:if($CLJS.n($CLJS.Me($CLJS.Qe(bW,a),b))){var c=a;b=Xma(a,b);a=c}else{c=$CLJS.cf.g(cW,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};eW=function(a){var b=$CLJS.rd(a);return b?(b=!$CLJS.uf(a))?$CLJS.zz($CLJS.be,$CLJS.Wa)($CLJS.z(a)):b:b};
WV=function(a){return $CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))?$CLJS.mh.h($CLJS.Gz(aW(a).toLowerCase(),/_/,"-")):a};fW=function(a,b){var c=eW(b);return $CLJS.n(c)?(b=WV($CLJS.z(b)),$CLJS.pd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};
iW=function(a){a=gW.g?gW.g(a,hW):gW.call(null,a,hW);var b=$CLJS.n($CLJS.tA.h(a))?$CLJS.pk.j(a,$CLJS.tA,$CLJS.mh):a;b=$CLJS.n($CLJS.tF.h(a))?$CLJS.pk.j(b,$CLJS.tF,$CLJS.mh):b;return $CLJS.n($CLJS.wQ.h(a))?$CLJS.pk.j(b,$CLJS.wQ,function(c){return $CLJS.n($CLJS.FD.h(c))?$CLJS.pk.j(c,$CLJS.FD,$CLJS.mh):c}):b};
Yma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Pd,$CLJS.Ui,WV,$CLJS.TM,WV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ae([a,b]);b=gW.g?gW.g(b,hW):gW.call(null,b,hW);return d.call(c,b,a)})};
Zma=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=WV(c);var d=Yma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Ui.h(a),$CLJS.GE)&&$CLJS.Ra($CLJS.TM.h(a))?$CLJS.R.j(a,$CLJS.TM,$CLJS.FF):a};$ma=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=aW(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Zma(b),$CLJS.U,c)],null)}),a)};
ana=function(a){a=gW.h?gW.h(a):gW.call(null,a);return jW.h?jW.h(a):jW.call(null,a)};kW=function(a){return $CLJS.VV($CLJS.VV($CLJS.cb(function(b,c){return $CLJS.VV(b,c,$CLJS.mh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.cC,$CLJS.vga,$CLJS.GN,bna,$CLJS.Yy,$CLJS.di],null)),$CLJS.fB,ana),$CLJS.VM,$CLJS.xU)};lW=function(a){return $CLJS.Ad(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,a,null],null):a};nW=function(a){return mW.h(lW(a))};
oW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return cW($CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(mW,b)))};pW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,nW(b)],null),$CLJS.cf.g(mW,c))};qW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,nW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
rW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,nW(a)],null)};sW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,mW.h(a)],null)};cna=function(a){return $CLJS.yf($CLJS.El(jW,tW(a)))};dna=function(a){for(;;)if($CLJS.n(eW(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Be($CLJS.ok.g(lW,a))};
uW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GE);return $CLJS.n(b)?$CLJS.pk.j(a,$CLJS.GE,mW):a};
ena=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,uW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,
2,5,$CLJS.Q,[l,uW(f)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};vW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.IP);return $CLJS.n(b)?$CLJS.pk.j(a,$CLJS.IP,ena):a};fna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.lP);b=$CLJS.Ra(a)?wW.h?wW.h(b):wW.call(null,b):b;return $CLJS.n(a)?vW(b):b};xW=function(a){return $CLJS.pd(a)?$CLJS.y(a):null!=a};
gna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.JE);var c=$CLJS.J.g(b,$CLJS.xM),d=$CLJS.J.g(b,$CLJS.UN),e=$CLJS.J.g(b,$CLJS.lP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.UN);var l=$CLJS.ZK($CLJS.fk.g(f,$CLJS.UN),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.UN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.pk.j(l,$CLJS.JE,wW):l,t=$CLJS.n(c)?$CLJS.pk.j(m,$CLJS.xM,$CLJS.Qe($CLJS.ok,jW)):m,u=$CLJS.n(e)?$CLJS.pk.j(t,$CLJS.lP,vW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.lP);return $CLJS.n(x)?$CLJS.R.j(jW($CLJS.fk.g(v,
$CLJS.lP)),$CLJS.lP,x):jW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Th($CLJS.hD("Error canonicalizing query: {0}",$CLJS.H([$CLJS.vU(m)])),new $CLJS.h(null,1,[$CLJS.JE,a],null),m);throw A;}};
hna=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.JE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.bF);c=$CLJS.J.g(c,$CLJS.VD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.rB,$CLJS.y($CLJS.ff($CLJS.Sa,function k(e,f){try{if($CLJS.ud(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ce(l,$CLJS.nF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,m,$CLJS.fk.g(t,$CLJS.tF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.CU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.yW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.VD],null),$CLJS.mk.g($CLJS.yf,$CLJS.Qe($CLJS.nk,b)))):a};
ina=function(a){try{return hna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Th($CLJS.MD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.JE,a],null),b);}throw c;}};
jna=function(a,b){var c=$CLJS.Wf.g($CLJS.fd(a),function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Yd.g(b,G);C=zW.g?zW.g(C,K):zW.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Yd.g(b,v);return zW.g?zW.g(A,C):zW.call(null,A,C)}();if(null!=u)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};kna=function(a,b){a=$CLJS.ok.g(function(c){var d=$CLJS.Yd.g(b,AW);return zW.g?zW.g(c,d):zW.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Sa,a))?a:null};
TV=function TV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ae(f,new $CLJS.ie(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),TV.g?TV.g(a,k):TV.call(null,a,k)):null},null,null))};
$CLJS.yW=function yW(a){switch(arguments.length){case 3:return yW.j(arguments[0],arguments[1],arguments[2]);case 4:return yW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return yW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return yW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return yW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.yW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.yW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.yW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.yW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.yW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.yW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.yW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.yW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.yW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ne.l($CLJS.yW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ne.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.yW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.yW.A=6;
var cW=function cW(a){for(;;){if($CLJS.sd(a))return $CLJS.gs(a,cW);if($CLJS.yd(a))a=$CLJS.yf(a);else if($CLJS.ud(a)){if($CLJS.Ra($CLJS.Me($CLJS.Sa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.nl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.dd(m);continue;case "and":return dW($CLJS.fr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.hr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));case "or":return dW($CLJS.kr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));default:return a}}else return a;case "and":return dW($CLJS.kr,t);case "or":return dW($CLJS.fr,t);default:return $CLJS.ok.g(cW,a)}}else return a}},lna=new $CLJS.M(null,"value_field","value_field",-980977878),BW=new $CLJS.M(null,"ascending","ascending",-988350486),
CW=new $CLJS.M(null,"named","named",-422393479),DW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),EW=new $CLJS.M(null,"descending","descending",-24766135),FW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),mna=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),GW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),HW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),nna=new $CLJS.M(null,"rows","rows",850049680),ona=
new $CLJS.M(null,"special-fn","special-fn",1290649344),AW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),hW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),pna=new $CLJS.M(null,"label_field","label_field",-1573182765),qna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),rna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),bna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var IW,sna=$CLJS.Se($CLJS.N),tna=$CLJS.Se($CLJS.N),una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.J.j($CLJS.N,$CLJS.Mk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));IW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.mk.g(WV,$CLJS.z),wna,sna,tna,una,vna);IW.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,b],null);return null!=a?$CLJS.Yd.g(b,a):b});
IW.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,b instanceof $CLJS.M?aW(b):b],null);a=$CLJS.Be($CLJS.YV(new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.tA,null,$CLJS.tF,null,$CLJS.wQ,null],null),null),iW(a)));return $CLJS.n(a)?$CLJS.Yd.g(b,a):b});
IW.m(null,$CLJS.EU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Yd.g(IW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EU,gW.g?gW.g(b,hW):gW.call(null,b,hW),WV(c)],null)});IW.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,iW(a)],null)});
IW.m(null,GW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[GW,b instanceof $CLJS.M?aW(b):b,$CLJS.mh.h(a)],null)});IW.m(null,FW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[FW,gW.g?gW.g(b,hW):gW.call(null,b,hW),$CLJS.Xr,WV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[FW,gW.g?gW.g(b,hW):gW.call(null,b,hW),WV(c)],null)});
IW.m(null,$CLJS.dB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Yd.g(IW.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,b,c,d],null)),gW.g?gW.g(a,hW):gW.call(null,a,hW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,gW.g?gW.g(b,hW):gW.call(null,b,hW),$CLJS.Ad(c)?c:WV(c),WV(d)],null)});
IW.m(null,$CLJS.$A,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$A,b,WV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,$CLJS.Qu],null)});IW.m(null,$CLJS.ZA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZA,b,WV(a)],null)});
IW.m(null,$CLJS.bB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,gW.g?gW.g(b,hW):gW.call(null,b,hW),c,WV(a)],null)});IW.m(null,$CLJS.eB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.eB,gW.g?gW.g(b,hW):gW.call(null,b,hW),c,WV(a)],null)});
IW.m(null,$CLJS.aB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aB,gW.g?gW.g(b,hW):gW.call(null,b,hW),WV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,gW.g?gW.g(b,hW):gW.call(null,b,hW)],null)});
IW.m(null,$CLJS.cB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,gW.g?gW.g(b,hW):gW.call(null,b,hW),WV(c),WV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cB,gW.g?gW.g(b,hW):gW.call(null,b,hW),WV(c)],null)});
IW.m(null,$CLJS.YA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YA,gW.g?gW.g(b,hW):gW.call(null,b,hW),gW.g?gW.g(c,hW):gW.call(null,c,hW),WV(a)],null)});IW.m(null,$CLJS.gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,b,a],null)});
IW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[WV(a)],null),$CLJS.cf.h(function(c){return gW.g?gW.g(c,hW):gW.call(null,c,hW)}),b)});
var JW=function JW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))){var c=WV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.ti,null,$CLJS.Nq,null,$CLJS.BF,null,$CLJS.wF,null,$CLJS.Zr,null,$CLJS.oF,null,$CLJS.GF,null,$CLJS.HF,null,$CLJS.SE,null,$CLJS.ZE,null,$CLJS.Mq,null,$CLJS.TE,null,$CLJS.AF,null,$CLJS.uF,null,$CLJS.Ej,null,$CLJS.Rw,null,$CLJS.hF,null,$CLJS.zF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(eW(a))?(a=$CLJS.z(a),JW.h?JW.h(a):JW.call(null,
a)):null},xna=new $CLJS.h(null,8,[$CLJS.Ui,WV,$CLJS.lP,function(a){a=XV(a);return $CLJS.y($CLJS.IP.h(a))?$CLJS.pk.j(a,$CLJS.IP,$ma):a},$CLJS.JE,new $CLJS.h(null,6,[$CLJS.cF,function KW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a)))return WV(a);if($CLJS.n(fW(CW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[CW,KW.h?KW.h(a):KW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(eW(a))?JW($CLJS.dd(a)):null)?$CLJS.ok.g(KW,a):gW.g?gW.g(a,
hW):gW.call(null,a,hW)},$CLJS.bP,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[aW(u),gW.g?gW.g(t,hW):gW.call(null,t,hW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[aW(l),gW.g?gW.g(f,hW):gW.call(null,f,hW)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.QQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n(eW(t))?IW.h(t):$CLJS.Zd(IW.h($CLJS.Zd(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}l=$CLJS.z(e);return $CLJS.ae($CLJS.n(eW(l))?IW.h(l):$CLJS.Zd(IW.h($CLJS.Zd(l))),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.KP,function(a){a=XV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.lP);if($CLJS.n(b))return a=$CLJS.BU(a,new $CLJS.h(null,1,[$CLJS.lP,$CLJS.JE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lP],null),a=gW.g?gW.g(a,b):gW.call(null,a,b),$CLJS.BU(a,new $CLJS.h(null,1,[$CLJS.JE,$CLJS.lP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JE],null);return gW.g?gW.g(a,b):gW.call(null,a,b)},$CLJS.UN,new $CLJS.h(null,1,[AW,kW],null),$CLJS.GM,new $CLJS.h(null,1,[AW,function(a){a=gW.g?gW.g(a,$CLJS.JE):gW.call(null,a,$CLJS.JE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.FD),d=$CLJS.J.g(b,$CLJS.VD);a=$CLJS.J.g(b,$CLJS.ZD);b=$CLJS.n(c)?$CLJS.pk.j(b,$CLJS.FD,WV):b;d=$CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(d))?$CLJS.pk.j(b,$CLJS.VD,WV):b;return $CLJS.n(a)?$CLJS.pk.j(d,$CLJS.ZD,aW):d}],null)],null),$CLJS.Gx,
new $CLJS.h(null,1,[$CLJS.rN,$CLJS.Pd],null),$CLJS.xM,new $CLJS.h(null,1,[AW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ui),d=$CLJS.J.g(b,$CLJS.pI),e=$CLJS.J.g(b,$CLJS.Ki);a=$CLJS.J.g(b,DW);b=$CLJS.n(e)?$CLJS.pk.j(b,$CLJS.Ki,aW):b;c=$CLJS.n(c)?$CLJS.pk.j(b,$CLJS.Ui,WV):b;d=$CLJS.n(d)?$CLJS.pk.j(c,$CLJS.pI,function(f){return gW.g?gW.g(f,hW):gW.call(null,f,hW)}):c;d=$CLJS.n(a)?$CLJS.yW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[DW,pna],null),function(f){return gW.g?gW.g(f,hW):gW.call(null,f,hW)}):d;return $CLJS.n(a)?
$CLJS.yW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[DW,lna],null),function(f){return gW.g?gW.g(f,hW):gW.call(null,f,hW)}):d}],null),$CLJS.sM,function(a){return null==a?null:WV(a)},$CLJS.UN,new $CLJS.h(null,1,[AW,kW],null),HW,WV],null),gW=function gW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return gW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
gW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.Bz(xna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=WV(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,gW.l(x,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=WV(u);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[u,gW.l(m,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),u)]))],null),k($CLJS.Hc(l)))}return null}},null,null)}(a)}()):$CLJS.n(eW(a))?IW.h(a):$CLJS.rd(a)?$CLJS.ok.g(function(e){return gW.l(e,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),AW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.hD("Error normalizing form: {0}",$CLJS.H([$CLJS.vU(d)])),new $CLJS.h(null,3,[$CLJS.Fi,a,$CLJS.Tk,c,ona,b],null),d);}throw e;}};gW.A=1;gW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var mW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Mk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(eW(f))?$CLJS.z(f):null},e,a,b,c,d)}();mW.m(null,$CLJS.Oh,function(a){return a});
mW.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(fW($CLJS.nF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return mW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,$CLJS.Be($CLJS.Fk.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,$CLJS.Be(a)],null)});
mW.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.od(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,b,a],null)});mW.m(null,$CLJS.VJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(eW(a))?mW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,a,null],null)});
mW.m(null,GW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,new $CLJS.h(null,1,[$CLJS.tA,a],null)],null)});mW.m(null,qna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=nW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=nW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,a,$CLJS.R.j(c,$CLJS.HM,b)],null)});
mW.m(null,rna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=nW(a);return $CLJS.Ne.v($CLJS.JU,a,$CLJS.R,$CLJS.H([$CLJS.wO,b]))});
mW.m(null,FW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=nW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.tA);$CLJS.Ra(d)||$CLJS.MR.g(d,b)?a=$CLJS.Ne.v($CLJS.JU,a,$CLJS.R,$CLJS.H([$CLJS.tF,b])):(c=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.mbql.util",c))&&(b=$CLJS.hD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv(),b):$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),mW.h(new $CLJS.P(null,3,5,$CLJS.Q,[FW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
mW.m(null,$CLJS.EU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=nW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,b,$CLJS.R.j(e,$CLJS.wQ,$CLJS.Fk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.FD,c],null),$CLJS.n(d)?$CLJS.Ae([c,d]):null,a])))],null)});
for(var LW=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.fr,$CLJS.hr],null)),MW=null,NW=0,OW=0;;)if(OW<NW){var PW=MW.X(null,OW);mW.m(null,PW,function(){return function(a){return oW(a)}}(LW,MW,NW,OW,PW));OW+=1}else{var QW=$CLJS.y(LW);if(QW){var RW=QW;if($CLJS.vd(RW)){var SW=$CLJS.ic(RW),yna=$CLJS.jc(RW),zna=SW,Ana=$CLJS.D(SW);LW=yna;MW=zna;NW=Ana}else{var TW=$CLJS.z(RW);mW.m(null,TW,function(){return function(a){return oW(a)}}(LW,MW,NW,OW,TW,RW,QW));LW=$CLJS.B(RW);MW=null;NW=0}OW=0}else break}
mW.m(null,$CLJS.VE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VE,nW(a),nW(b)],null),c)});mW.m(null,$CLJS.dB,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=nW(a);a=$CLJS.n(bW($CLJS.nF,a))?$CLJS.JU.l(c,$CLJS.fk,$CLJS.H([$CLJS.tF])):c;return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dB,a],null),b)});
for(var UW=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.eF,$CLJS.yF,$CLJS.mF,$CLJS.fF,$CLJS.Pj,$CLJS.DF,$CLJS.Iq,$CLJS.Kq,$CLJS.Eq,$CLJS.Gq,$CLJS.QE,$CLJS.XE,$CLJS.OE,$CLJS.$E,$CLJS.PE],null)),VW=null,WW=0,XW=0;;)if(XW<WW){var YW=VW.X(null,XW);mW.m(null,YW,function(){return function(a){return pW(a)}}(UW,VW,WW,XW,YW));XW+=1}else{var ZW=$CLJS.y(UW);if(ZW){var $W=ZW;if($CLJS.vd($W)){var aX=$CLJS.ic($W),Bna=$CLJS.jc($W),Cna=aX,Dna=$CLJS.D(aX);UW=Bna;VW=Cna;WW=Dna}else{var bX=$CLJS.z($W);mW.m(null,bX,
function(){return function(a){return pW(a)}}(UW,VW,WW,XW,bX,$W,ZW));UW=$CLJS.B($W);VW=null;WW=0}XW=0}else break}mW.m(null,nna,function(){return null});mW.m(null,$CLJS.xL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xL,mW.h(b),a],null)});
mW.m(null,CW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=mW.h;var e=$CLJS.Q;b=mW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,mna)?new $CLJS.h(null,1,[$CLJS.U,a],null):new $CLJS.h(null,1,[$CLJS.oD,a],null);return c.call(mW,new $CLJS.P(null,3,5,e,[$CLJS.xL,b,a],null))});
for(var cX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rw,$CLJS.oF],null)),dX=null,eX=0,fX=0;;)if(fX<eX){var gX=dX.X(null,fX);mW.m(null,gX,function(){return function(a){return qW(a)}}(cX,dX,eX,fX,gX));fX+=1}else{var hX=$CLJS.y(cX);if(hX){var iX=hX;if($CLJS.vd(iX)){var jX=$CLJS.ic(iX),Ena=$CLJS.jc(iX),Fna=jX,Gna=$CLJS.D(jX);cX=Ena;dX=Fna;eX=Gna}else{var kX=$CLJS.z(iX);mW.m(null,kX,function(){return function(a){return qW(a)}}(cX,dX,eX,fX,kX,iX,hX));cX=$CLJS.B(iX);dX=null;eX=0}fX=0}else break}
for(var lX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.hF,$CLJS.IF,$CLJS.SE,$CLJS.BF,$CLJS.zF,$CLJS.ti,$CLJS.Ej,$CLJS.AF,$CLJS.TE],null)),mX=null,nX=0,oX=0;;)if(oX<nX){var pX=mX.X(null,oX);mW.m(null,pX,function(){return function(a){return rW(a)}}(lX,mX,nX,oX,pX));oX+=1}else{var qX=$CLJS.y(lX);if(qX){var rX=qX;if($CLJS.vd(rX)){var sX=$CLJS.ic(rX),Hna=$CLJS.jc(rX),Ina=sX,Jna=$CLJS.D(sX);lX=Hna;mX=Ina;nX=Jna}else{var tX=$CLJS.z(rX);mW.m(null,tX,function(){return function(a){return rW(a)}}(lX,mX,nX,oX,
tX,rX,qX));lX=$CLJS.B(rX);mX=null;nX=0}oX=0}else break}mW.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,nW(b),a],null)});
for(var uX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uF,$CLJS.wF],null)),vX=null,wX=0,xX=0;;)if(xX<wX){var yX=vX.X(null,xX);mW.m(null,yX,function(){return function(a){return sW(a)}}(uX,vX,wX,xX,yX));xX+=1}else{var zX=$CLJS.y(uX);if(zX){var AX=zX;if($CLJS.vd(AX)){var BX=$CLJS.ic(AX),Kna=$CLJS.jc(AX),Lna=BX,Mna=$CLJS.D(BX);uX=Kna;vX=Lna;wX=Mna}else{var CX=$CLJS.z(AX);mW.m(null,CX,function(){return function(a){return sW(a)}}(uX,vX,wX,xX,CX,AX,zX));uX=$CLJS.B(AX);vX=null;wX=0}xX=0}else break}
mW.m(null,$CLJS.GF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,mW.h(b),mW.h(a)],null)});
mW.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Yd.g(mW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,b],null)),gW.l(a,$CLJS.H([hW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,$CLJS.yf(function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[mW.h(v),mW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[mW.h(m),mW.h(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}(b)}())],null)});
mW.m(null,$CLJS.FE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,mW.h(a),$CLJS.E.g(0,b)?1:mW.h(b)],null),$CLJS.cf.g(mW,c))});
var jW=function jW(a){if($CLJS.td(a))return $CLJS.Od(function(d,e,f){return $CLJS.R.j(d,e,jW.h?jW.h(f):jW.call(null,f))},a,a);if($CLJS.sd(a))return $CLJS.gs(a,jW);if($CLJS.n(eW(a))){var c=function(){try{return mW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Mz($CLJS.Cx);if($CLJS.n($CLJS.Lz("metabase.mbql.normalize",e))){var f=$CLJS.MD("Invalid clause:");f instanceof Error?$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([a])),f):$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([f,
a])),null)}throw $CLJS.Th($CLJS.hD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.vU(d)])),new $CLJS.h(null,1,[$CLJS.aR,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Yd.g($CLJS.fd(c),$CLJS.z(c)),$CLJS.cf.h(jW),$CLJS.Hc(c)):c}return $CLJS.yd(a)?$CLJS.ok.g(jW,a):$CLJS.pd(a)?$CLJS.Wf.j($CLJS.fd(a),$CLJS.cf.h(jW),a):a},tW=function tW(a){return function f(d,e){try{if($CLJS.n(function(){var x=ZV($CLJS.yd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=ZV($CLJS.be);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.ud(e)&&2<=$CLJS.D(e))try{var k=$CLJS.tk.j(e,0,2);if($CLJS.ud(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.fD($CLJS.be,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Nq,null,$CLJS.Zr,null,$CLJS.HF,null,$CLJS.Mq,null,
CW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(JW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.gD(tW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.ud(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.tk.j(e,0,1);if($CLJS.ud(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},DX=function DX(a){return function f(d,e){try{var k=ZV($CLJS.yd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.ud(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ce(m,$CLJS.kF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,
$CLJS.dF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,BW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,EW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(m=X,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ce(v,BW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,t],null));throw $CLJS.Z;}catch(T){if(T instanceof Error)if(m=T,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,EW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.kF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,nW(t)],null);throw $CLJS.Z;}catch(oa){if(oa instanceof
Error)if(m=oa,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.dF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,nW(t)],null);throw $CLJS.Z;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ma;}else throw m;else throw oa;}else throw m;else throw da;}else throw m;else throw T;}else throw m;else throw X;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.ud(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.tk.j(e,0,0);if($CLJS.ud(x)&&0===$CLJS.D(x))try{var A=$CLJS.tk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.Gk.h($CLJS.cf.g(DX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.$V(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.xf,a)},wW=$CLJS.mk.g(jW,function(a){var b=xW($CLJS.cF.h(a))?$CLJS.pk.j(a,$CLJS.cF,cna):a;b=xW($CLJS.bF.h(a))?$CLJS.pk.j(b,$CLJS.bF,dna):b;b=xW($CLJS.VD.h(a))?$CLJS.pk.j(b,$CLJS.VD,$CLJS.Qe($CLJS.ok,lW)):b;b=xW($CLJS.QQ.h(a))?$CLJS.pk.j(b,$CLJS.QQ,DX):b;return xW($CLJS.KP.h(a))?$CLJS.pk.j(b,$CLJS.KP,fna):b}),Nna=new $CLJS.h(null,3,[$CLJS.lP,$CLJS.Pd,$CLJS.JE,new $CLJS.h(null,2,[$CLJS.KP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.lP);if($CLJS.n(b))return a=
$CLJS.BU(a,new $CLJS.h(null,1,[$CLJS.lP,$CLJS.JE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lP],null),a=zW.g?zW.g(a,b):zW.call(null,a,b),$CLJS.BU(a,new $CLJS.h(null,1,[$CLJS.JE,$CLJS.lP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JE],null);return zW.g?zW.g(a,b):zW.call(null,a,b)},$CLJS.GM,new $CLJS.h(null,1,[AW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JE],null);return zW.g?zW.g(a,b):zW.call(null,a,b)}],null)],null),HW,$CLJS.Pd],null),zW=function zW(a){switch(arguments.length){case 1:return zW.h(arguments[0]);
case 2:return zW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};zW.h=function(a){return zW.g(a,$CLJS.xf)};zW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.Bz(Nna,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?jna(a,b):$CLJS.rd(a)?kna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Fi,a,$CLJS.Tk,b],null),d);throw d;}};zW.A=2;
$CLJS.EX=function(){var a=$CLJS.mk.l(zW,ina,gna,$CLJS.H([gW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.hD("Error normalizing query: {0}",$CLJS.H([$CLJS.vU(c)])),new $CLJS.h(null,1,[$CLJS.JE,b],null),c);}throw d;}}}();$CLJS.FX=function FX(a,b){if($CLJS.Ra($CLJS.y(a)))a=(0,$CLJS.EX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.es(a);b=$CLJS.Ae([$CLJS.ed(a),b]);f=FX.g?FX.g(f,b):FX.call(null,f,b);a=e.call(d,f,$CLJS.ed(a))}return a};