var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var fU,gU,hU,jU,kU,lU,mU,nU,oU,pU;fU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);gU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);hU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.iU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);jU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);kU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);lU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
mU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);nU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);oU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);pU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(fU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hj,new $CLJS.h(null,1,[$CLJS.ts,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.aU)));$CLJS.Y(jU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Hj,$CLJS.JL,$CLJS.yM,$CLJS.GE,$CLJS.Ps,$CLJS.Mj,$CLJS.EO],null));
$CLJS.Y(mU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oD,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.tD,$CLJS.sr],null)],null)],null));
$CLJS.Y(nU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.el],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null));
$CLJS.Y(kU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.GE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yr,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Dj],null)],null)],null)],null));
$CLJS.Y(lU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.JL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vL,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.hK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.XJ],null)],null)],null));
$CLJS.Y(pU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.yM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eQ,$CLJS.WJ],null)],null)],null));$CLJS.Y(oU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hj],null),$CLJS.PT));
$CLJS.Y(gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oU],null)],null)],null)],null));
$CLJS.Y(hU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.yi,$CLJS.Ui],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$n,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null)],null)],null)],null));
$CLJS.Y($CLJS.iU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,$CLJS.tD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.U.h(b))},a)}],null)],null));